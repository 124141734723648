import React, { Component } from 'react';

export class LogOutPage extends Component {
  constructor(props) {
    super();
    this.state = { ...props };
  }
  componentWillMount() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href =
      process.env.REACT_APP_STAGE === 'prod'
        ? `${process.env.REACT_APP_TRUE_FINGERPRINTS_WEBSITE_URL}`
        : `${process.env.REACT_APP_TRUE_FINGERPRINTS_WEBSITE_URL}:${process.env.REACT_APP_TRUE_FINGERPRINTS_WEBSITE_PORT}/login`;
  }
  render() {
    return <section>Redirecting...</section>;
  }
}

export default LogOutPage;
