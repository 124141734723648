export default Object.freeze({
  OrderTypes: {
    oneTimeCheck: 1,
    monthly: 2
  },
  OrderResult: {
    Pending: 1,
    ClearResults: 2,
    Alerts: 3
  }
});
