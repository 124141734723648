import {
  UPDATE_USER_SUCCESS,
  GET_USER_SUCCESS,
  GET_USER_CARDS_SUCCESS,
  GET_USERS_SUCCESS,
  UPDATE_USER_BY_ADMIN_SUCCESS,
  GET_USER_TRANSACTIONS_SUCCESS
} from '../common/constants';
export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_SUCCESS:
      return {
        result: action.user
      };
    case GET_USER_SUCCESS: {
      return {
        user: action.user
      };
    }
    case GET_USER_CARDS_SUCCESS: {
      return {
        ...state,
        cards: action.cards
      };
    }
    case GET_USERS_SUCCESS:
      return {
        ...state,
        usersList: action.users
    };
    case UPDATE_USER_BY_ADMIN_SUCCESS:
      return {
        result: action.user
      };
    case GET_USER_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        userTransactions: action.userTransactions
      };
    }
    default:
      return state;
  }
};
