import React, { Component } from "react";
import { connect } from 'react-redux';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { confirmAlert } from 'react-confirm-alert'; 
import ReactPaginate from 'react-paginate';
import AddAuthorizationPopUp from "../Modals/AddAuthorization";
import { getAuths } from "../../actions/authorizationActions";
import UserApi from './../../api/user-api';
import apiCallsHelper from '../../api/apiCallsHelper';
import config from '../../common/config';
import ToastService from '../Toasts/toastService';


class FcraPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      searchText: '',
    };
    this.showModal = this.showModal.bind(this);
    this.submitBeginAuthorization = this.submitBeginAuthorization.bind(this);
    this.findAuthorizations = this.findAuthorizations.bind(this);
  }

  showModal() {
    this.setState({ showModal: true });
  }

  findAuthorizations() {
    this.loadAuthorizations(1);
  }

  handlePageClick = e => {
    this.loadAuthorizations(e.selected + 1);
  };

  async componentDidMount() {
    try {
      this.loadAuthorizations(1);
      let user = await UserApi.getUserWithJwt();

      if (user) {
        this.setState({
          user: user
        });
      }
    } catch (error) { }
  }

  loadAuthorizations(page) {
    const req = {
      userId: this.state.user,
      page: page,
      searchText: this.state.searchText
    };
    this.props.getAuths(req);
  }

  submitBeginAuthorization = (id, firstName, lastName, email, user, unique) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui w-50 mx-auto'>
            <h1>Begin Authorization?</h1>
            <p>You are about to begin an FCRA Authorization process for {firstName} {lastName}.
                <br></br>{firstName} {lastName} will receive an authorization email under FCRA regulations notifying 
                the individual that you would like to initiate Pre-Hire or Post-Hire screening 
                and that the individual has rights under the Fair Credit Reporting act to verify 
                the information that was provided and to dispute inaccuracy. 
                The authorization will be carried out by email, make sure that the email address is correct.
                <br></br>Would you like to proceed?
                </p>
            <Button variant='outline-secondary mt-3' onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant='success ml-4 mt-3'
              onClick={() => {
                this.handleBeginAuthorization(id, firstName, lastName, email, user, unique);
                onClose();
              }}
            >
              Proceed
            </Button>
          </div>
        );
      }
    });
  };

  async handleBeginAuthorization(id, firstName, lastName, email, user, unique) {
    let data = { id, firstName, lastName, email, user, unique} 
      try{
          const result = await apiCallsHelper.postWithJWT(
            `${config.trueFingerPrintsAPI.baseURL}/approve-auth`, data
          );
          if (result && result.status === 200) {
            ToastService.show(
              'Authorization sent to Individual.',
              'success'
            );
            this.loadAuthorizations(1);
          }
      } catch (error) {
        console.log(error)
      }
  }

 viewAuthorization(id, unique) {
    return   process.env.REACT_APP_STAGE === 'prod'
    ? `${process.env.REACT_APP_TRUE_FINGERPRINTS_WEBSITE_URL}:${process.env.REACT_APP_TRUE_FINGERPRINTS_WEBSITE_PORT}/fcra-auth/${unique}`
    : `${process.env.REACT_APP_TRUE_FINGERPRINTS_WEBSITE_URL}`;
  }     

  render() {
    return (
        <Container fluid>
        <Row className='breadcrumb-section'>
          <Col lg={12}>
            <Breadcrumb className='mt-2'>
              <Breadcrumb.Item><Link to='/'>Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item active>Authorization</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className='button-action intro-text text-center mb-5'>
          <Col lg={12}>
          <h1>Authorization</h1>
            <h5 className='mt-4 mb-5'>
            Under FCRA Regulations, you must receive consent before running Pre-Hire or Post-Hire 
            background screening for a potential candidate. 
            You can obtain consent manually by sending a form to a candidate 
            to sign, or you can do an automatic Authorization through email. 
            If you would like to do this process manually, 
            head over to the "Forms" menu above, or you can add an authorization
             below and follow the instructions.
            </h5>
              <Button variant='success' size='lg'
                onClick={() => this.showModal( )}
              >    
                <i className='material-icons'>playlist_add</i>Add Authorization
              </Button>
          </Col>
        </Row>
        <Row className="table-section">
          <Col lg={12}>
          <figure className='icon-group mt-5'>
              <h2 className='heading ml-3'>Authorizations</h2>
            </figure>
            <section className="list-box mt-3">
              <Table responsive="xl" className="mb-5">
                <thead>
                  <tr>
                    <th>Authorization</th>
                    <th>FCRA Auth ID</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.authList
                    ? this.props.authList.rows.map(i => {
                      return (    
                        <tr key={i.id}>
                          <td>{i.firstName + " " + i.lastName}</td>
                          <td>{i.status ? i.unique : "" }</td>
                          {i.status === "Y" ?
                          <td>Authorized</td>  
                          : i.status === "N" ?
                          <td className="text-danger">Not Authorized</td> 
                          : <td className="text-warning">Not Authorized - Pending Individual Confirmation</td> 
                          }
                          <td>{i.status !== "N"? 
                            <Button variant="outline-primary" disabled>
                              <i className="material-icons">playlist_add_check</i>
                              Begin Authorization
                            </Button>
                            :
                            <Button variant="outline-primary"  onClick={() => this.submitBeginAuthorization(i.id, i.firstName, i.lastName, i.email, this.state.user.fullName, i.unique)}>
                              <i className="material-icons">playlist_add_check</i>
                              Begin Authorization
                            </Button>
                             }
                          </td>
                          <td>{i.status === "Y" ? 
                            <Button variant="outline-primary" >
                              <a href={this.viewAuthorization(i.id, i.unique)} target="_blank"><i className="material-icons">playlist_play</i>
                              View Authorization
                              </a>
                            </Button>
                            :
                            <Button variant="outline-primary" disabled>
                            <i className="material-icons">playlist_play</i>
                            View Authorization
                          </Button>
                           }
                          </td>
                        </tr>
                      );
                    })
                    : null}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={'< Previous'}
                nextLabel={'Next >'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={
                  this.props.authList
                    ? Math.ceil(this.props.authList.count / 10)
                    : 1
                }
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={'list-group-pagination pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                disabledClassName={'page-item disabled'}
                activeClassName={'page-item active'}
              />
            </section>
          </Col>
        </Row>
        {this.state.showModal ? (
          <AddAuthorizationPopUp
            user={this.state.user}
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
          />
        ) : null}
      </Container>
    );
  }
}

const mapActionsToProps = {
  getAuths: getAuths
};

const mapStateToProps = state => {
  return {
    authList: state.authorizationReducer.authList
  };
};

export default connect(mapStateToProps, mapActionsToProps)(FcraPage);
