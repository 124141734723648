import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import UserProfilePage from '../User/userProfilePage';
import CreditCardInput from 'react-credit-card-input';
import ApiHelper from '../../api/apiCallsHelper.js';
import config from '../../common/config';
import ToastService from '../Toasts/toastService';
import Loader from '../Spinners/spinnerPage';
import cardValidation from '../../validations/cardValidation';

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      cardNumber: '',
      expiry: '',
      cvc: '',
      billingAddress: '',
      billingZipCode: '',
      showLoader: false,
      errors: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeCard = this.handleChangeCard.bind(this);
    this.addCard = this.addCard.bind(this);
  }

  async componentDidMount() {
    try {
      let cards = await ApiHelper.getWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/users/cards/all`
      );
      this.setState({
        cards: cards.data
      });
    } catch (error) { }
  }

  cardFormIsValid() {
    const validation = cardValidation(this.state);

    if (validation.errors) {
      this.setState({ errors: validation.errors });
    }

    return validation.isValid;
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleChangeCard(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async addCard(e) {
    e.preventDefault();
    if (this.cardFormIsValid()) {
      this.setState({ showLoader: true });
      const expiry = this.state.expiry.split('/');
      let card = null;
      try {
        card = await ApiHelper.postWithJWT(
          `${config.trueFingerPrintsAPI.baseURL}/subscriptions/add-cards`,
          {
            card: {
              number: this.state.cardNumber.replace(/\s/g, ''),
              exp_month: parseInt(expiry[0].trim()),
              exp_year: parseInt(
                new Date()
                  .getFullYear()
                  .toString()
                  .substr(0, 2) + expiry[1].trim()
              ),
              cvc: parseInt(this.state.cvc, 10),
              address_line1: this.state.billingAddress,
              address_zip: parseInt(this.state.billingZipCode, 10)
            }
          }
        );
        this.setState({ showLoader: false });
        let cards = this.state.cards;
        cards.push(card.data.Card);
        this.setState({
          cards: cards,
          cardNumber: '',
          expiry: '',
          cvc: '',
          billingAddress: '',
          billingZipCode: ''
        });
        ToastService.show('Successfully added new card!', 'success');
      } catch (err) {
        console.log('error', err.response);
        this.setState({ showLoader: false });
        ToastService.show(err.response.statusText, 'error');
      }
    }
  }

  showToast() {
    ToastService.show('Successfully added new card!', 'success');
  }

  render() {
    const loader = this.state.showLoader ? <Loader /> : null;
    return (
      <Container>
        <Row>
          <Col lg={12}>
            <UserProfilePage />
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Card bg='secondary' className='card-box-dark mt-4'>
              <Card.Title className='text-white'>
                <h2>
                  <i className='material-icons md-36 mt-4 ml-3'>credit_card</i>
                  Your Cards
                </h2>
              </Card.Title>
              <ul className='list-unstyled text-white'>
                {this.state.cards.map(obj => {
                  return (
                    <li className='text-white ml-3' key={obj.id}>
                      {obj.brand} - {obj.last4}{' '}
                    </li>
                  );
                })}
              </ul>
              <h5 className='mt-5 mb-4 ml-3 text-white'>Add Credit Card</h5>

              <Card className='form-payment ml-3 mr-3 mb-3'>
                <Card.Body>
                  <Form className='text-left ml-3'>
                    <Form.Group>
                      <CreditCardInput
                        onError={({ inputName, err }) =>
                          this.setState({ isValid: false })
                        }
                        cardNumberInputProps={{
                          value: this.state.cardNumber,
                          onChange: this.handleChangeCard,
                          name: 'cardNumber'
                        }}
                        cardExpiryInputProps={{
                          value: this.state.expiry,
                          onChange: this.handleChangeCard,
                          name: 'expiry'
                        }}
                        cardCVCInputProps={{
                          value: this.state.cvc,
                          onChange: this.handleChangeCard,
                          name: 'cvc'
                        }}
                        fieldClassName='input'
                      />
                    </Form.Group>
                    <hr />
                    <br />
                    <Form.Group controlId='formBillingAddress'>
                      <Form.Label>Billing Address</Form.Label>
                      <Form.Control
                        className='text-black'
                        type='text'
                        placeholder='Enter your Billing Address'
                        name='billingAddress'
                        value={this.state.billingAddress}
                        onChange={this.handleChange}
                      />
                      {this.state.errors.billingAddress ? (
                        <p className='text-danger'>
                          {this.state.errors.billingAddress}
                        </p>
                      ) : null}
                    </Form.Group>
                    <Form.Group controlId='formBillingZipCode'>
                      <Form.Label>Billing Zip Code</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter your Billing Zip Code'
                        name='billingZipCode'
                        value={this.state.billingZipCode}
                        onChange={this.handleChange}
                      />
                      {this.state.errors.billingZipCode ? (
                        <p className='text-danger'>
                          {this.state.errors.billingZipCode}
                        </p>
                      ) : null}
                    </Form.Group>
                    <br />
                    {loader}
                    <Button
                      className='btn-submit-card mt-2 mb-2'
                      type='submit'
                      value='Submit'
                      variant='primary'
                      onClick={this.addCard}
                    >
                      <i className='material-icons'>credit_card</i>
                      Save Card
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default SettingsPage;
