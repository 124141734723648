import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
class CaseDetails extends Component {
  render() {
    return (
      <div>
        <Row>
          <Col lg={12}>
            <h5 className='text-center box title-box-secondary'>
              Case:
              <span className='case-number pl-2' />#
              {this.props.caseDetails.case_number}
            </h5>
            <h5 className='text-center box title-box-secondary'>
              Court:
              <span className='court-name pl-2' />
              {this.props.caseDetails.court}
            </h5>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <article className='results-component order-info mt-3 mb-5'>
              <ListGroup variant='flush'>
                <h5 className='ml-3 mb-4 mt-3'>Identifiers</h5>
                <ListGroup.Item>
                  First Name:
                  <span className='pl-2'>
                    {this.props.caseDetails.first_name}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Middle Name:
                  <span className='pl-2'>
                    {this.props.caseDetails.middle_name}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Last Name:
                  <span className='pl-2'>
                    {this.props.caseDetails.last_name}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Date of Birth:
                  <span className='pl-2'>
                    {this.props.caseDetails.dob}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Alias(es):
                  <span className='pl-2'>
                    {this.props.caseDetails.aliases}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  SSN:<span className='pl-2'>{this.props.caseDetails.ssn}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Gender:<span className='pl-2'>{this.props.caseDetails.gender}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Race:<span className='pl-2'>{this.props.caseDetails.race}White</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Height:<span className='pl-2'>{this.props.caseDetails.height}6"</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Weight:<span className='pl-2'>{this.props.caseDetails.weight}180lbs</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Hair Color:<span className='pl-2'>{this.props.caseDetails.hair_color}Brown</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Eye Color:<span className='pl-2'>{this.props.caseDetails.eye_color}Brown</span>
                </ListGroup.Item>
              </ListGroup>
            </article>
          </Col>
          <Col lg={6}>
            <article className='results-component subject-info mt-3 mb-5'>
              <ListGroup variant='flush'>
                <h5 className='ml-3 mb-4 mt-3'>Identity/Dates</h5>
                <ListGroup.Item>
                  Case Number:<span className='pl-2'>{this.props.caseDetails.case_number}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  File Date:<span className='pl-2'>{this.props.caseDetails.file_date}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Arrest Date:<span className='pl-2'>{this.props.caseDetails.offense_date}</span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Offense Date:<span className='pl-2'>{this.props.caseDetails.arrest_date}</span>
                </ListGroup.Item>
              </ListGroup>
            </article>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CaseDetails;
