import jwtDecode from 'jwt-decode';

export const getUserFromJWT = () => {
  const ssToken = window.sessionStorage.getItem('jwt');
  const lsToken = window.localStorage.getItem('jwt');

  if (ssToken) {
    return jwtDecode(ssToken);
  }
  if (lsToken) {
    return jwtDecode(lsToken);
  }
};
