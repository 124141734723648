import React from 'react';
import classnames from 'classnames';

const SelectFieldGroupFromArray = ({ value, label, error, onChange , options , name , placeholder, required }) => {
  return (
    <div className={classnames('form-group', { 'has-error': error })}>
      <label className="control-label">{label} {required ? <span className="text-danger"> *</span> : null}</label>
      <select onChange={onChange} className="form-control" value={value} name={name}>
        <option value="" disabled>{placeholder}</option>
       {options.map((o)=> <option key={o} value={o}>{o}</option> )}
      </select>
      {error && <span className="help-block text-danger">{error}</span>}
    </div>
  );
};

export default SelectFieldGroupFromArray;
