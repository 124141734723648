import axios from 'axios';
import config from '../common/config';

class SubjectApi {
  addSubject(subject) {
    const token = localStorage.getItem('jwt')
      ? localStorage.getItem('jwt')
      : sessionStorage.getItem('jwt');

    const configAPi = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    return axios
      .post(
        `${config.trueFingerPrintsAPI.baseURL}/individuals/add`,
        {
          name_first: subject.firstName,
          name_last: subject.lastName,
          name_middle: subject.middleName,
          dob: subject.dob,
          ssn: subject.ssn,
          gender: subject.gender,
          city: subject.city,
          state: subject.state,
          country: subject.country,
          county: subject.county,
          phone: subject.phone,
          address1: subject.address1,
          address2: subject.address2,
          email: subject.email,
          zip: subject.zip
        },
        configAPi
      )
      .then(r => {
        return r.data;
      })
      .catch(err => {
        return err;
      });
  }

  editSubject(subject) {
    const token = localStorage.getItem('jwt')
      ? localStorage.getItem('jwt')
      : sessionStorage.getItem('jwt');

    const configAPi = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    return axios
      .post(
        `${config.trueFingerPrintsAPI.baseURL}/individuals/update`,
        {
          subject_id: subject.subject_id,
          name_first: subject.firstName,
          name_last: subject.lastName,
          name_middle: subject.middleName,
          dob: subject.dob,
          ssn: subject.ssn,
          gender: subject.gender,
          city: subject.city,
          state: subject.state,
          county: subject.county,
          phone: subject.phone,
          address1: subject.address1,
          address2: subject.address2,
          email: subject.email,
          zip: subject.zip,
          country: subject.country
        },
        configAPi
      )
      .then(r => {
        return r;
      })
      .catch(err => {
        return err;
      });
  }
}

export default new SubjectApi();
