import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import TextFieldGroup from '../../common/textFieldGroup';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { updateUserByAdmin } from '../../../actions/userActions';
import { withRouter } from 'react-router';
import { getUserWithJwt } from '../../../actions/userActions';
import validateEditUser from '../../../validations/editUserValidation';
import ToastService from '../../Toasts/toastService';
import Loader from '../../Spinners/spinnerPage';

class EditUserPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: '',
      fullName: '',
      email: '',
      phone: '',
      errors: {},
      isValid: {},
      oldEmail: '',
      showLoader: false,
      preHireCharge: 0,
      postHireCharge: 0,
      preHireLimit: 0,
      postHireLimit: 0
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async onSubmit(e) {
    e.preventDefault();
    this.setState({ showLoader: true });
    try {
      const isValid = await this.isValid();
      if (isValid) {
        this.setState({ errors: {} });
        this.props.updateUserByAdmin({
          fullName: this.state.fullName,
          email: this.state.email,
          phone: this.state.phone,
          id: this.props.location.state.user.Id,
          preHireCharge: this.state.preHireCharge,
          postHireCharge: this.state.postHireCharge,
          preHireLimit: this.state.preHireLimit,
          postHireLimit: this.state.postHireLimit
        });
      }
      this.setState({ showLoader: false });
      ToastService.show('Successfully edited user!', 'success');
    } catch (error) {
      // handle error
      console.log('error', error.response);
      this.setState({ showLoader: false });
      ToastService.show(
        `${error.response.statusText},please contact customer support.`,
        'error'
      );
    }
  }

  async isValid() {
    try {
      let { errors, isValid } = await validateEditUser(
        this.state,
        this.state.oldEmail
      );

      if (!isValid) {
        this.setState({ errors });
      }

      return isValid;
    } catch (error) { }
  }

  async componentDidMount() {
    this.setState({
      fullName: this.props.location.state.user.fullName,
      phone: this.props.location.state.user.phone,
      email: this.props.location.state.user.email,
      preHireCharge: this.props.location.state.user.preHireCharge,
      postHireCharge: this.props.location.state.user.postHireCharge,
      preHireLimit: this.props.location.state.user.preHireLimit,
      postHireLimit: this.props.location.state.user.postHireLimit
    });
  }

  componentWillMount() {
    if (
      localStorage.getItem('userRole') &&
      localStorage.getItem('userRole') !== 'sysadmin'
    ) {
      window.location.href = '/error';
    } else if (
      sessionStorage.getItem('userRole') &&
      sessionStorage.getItem('userRole') !== 'sysadmin'
    ) {
      window.location.href = '/error';
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user) {
      this.setState({
        fullName: newProps.user.fullName,
        phone: newProps.user.phone,
        email: newProps.user.email,
        userId: newProps.user.Id,
        oldEmail: newProps.user.email,
        preHireCharge: newProps.preHireCharge,
        postHireCharge: newProps.user.postHireCharge,
        preHireLimit: newProps.user.preHireLimit,
        postHireLimit: newProps.user.postHireLimit
      });
    }
  }

  showToast() {
    ToastService.show('Successfully edited user!', 'success');
  }

  render() {
    const loader = this.state.showLoader ? <Loader /> : null;
    return (
      <Container>
        <Row>
          <Col lg={12}>
            <Card className='card-box mt-5'>
              <Form onSubmit={this.onSubmit}>
                <Card.Body>
                  <Card.Title>
                    <h2>
                      <i className='material-icons md-36'>person_add</i>Edit
                      User
                    </h2>
                  </Card.Title>
                  <h5 className='mt-5 mb-3'>User's Personal Info</h5>
                  <Card.Text />
                  <TextFieldGroup
                    error={this.state.errors.fullName}
                    label='Full Name'
                    onChange={this.onChange}
                    value={this.state.fullName}
                    field='fullName'
                    required={true}
                  />
                  <TextFieldGroup
                    error={this.state.errors.email}
                    label='Email address'
                    onChange={this.onChange}
                    value={this.state.email}
                    field='email'
                    required={true}
                  />
                  <TextFieldGroup
                    error={this.state.errors.phone}
                    label='Phone'
                    onChange={this.onChange}
                    value={this.state.phone}
                    field='phone'
                  />
                  <TextFieldGroup
                    label='PreHire Charge'
                    onChange={this.onChange}
                    value={this.state.preHireCharge}
                    field='preHireCharge'
                    type='number'
                  />
                  <TextFieldGroup
                    label='PostHire Charge'
                    onChange={this.onChange}
                    value={this.state.postHireCharge}
                    field='postHireCharge'
                    type='number'
                  />
                  <TextFieldGroup
                    label='PreHire Limits'
                    onChange={this.onChange}
                    value={this.state.preHireLimit}
                    field='preHireLimit'
                    type='number'
                  />
                  <TextFieldGroup
                    type='number'
                    label='PreHire Limits'
                    onChange={this.onChange}
                    value={this.state.postHireLimit}
                    field='postHireLimit'
                  />
                  {loader}
                  <Button variant='primary' className='mt-3 mb-2' type='submit'>
                    <i className='material-icons'>person_add</i>
                    Edit User
                  </Button>
                </Card.Body>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapActionsToProps = {
  updateUserByAdmin,
  getUserWithJwt
};

const mapStateToProps = state => {
  return {
    user: state.userReducer.user
  };
};

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(EditUserPage)
);
