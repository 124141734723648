import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import ToastService from '../Toasts/toastService';
import Loader from '../Spinners/spinnerPage';
import TextFieldGroup from '../common/textFieldGroup';
import { addAuth, getAuths } from '../../actions/authorizationActions';
import validateAddAuthorization from '../../validations/addAuthorizationValidation';
import UserApi from './../../api/user-api';

class AddAuthorizationPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
        firstName: '',
        lastName: '',
        dob: '',
        email: '',
        showLoader: false,
        errors: {},
        isValid: {},
        user: null,
        searchText: '',
      };
    this.onChange = this.onChange.bind(this);
    this.submitAuthorization = this.submitAuthorization.bind(this);
    this.findAuthorizations = this.findAuthorizations.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  findAuthorizations() {
    this.loadAuthorizations(1);
  }

  async componentDidMount() {
    try {
      this.loadAuthorizations(1);
      let user = await UserApi.getUserWithJwt();

      if (user) {
        this.setState({
          user: user.Id
        });
      }
    } catch (error) { }
  }

  loadAuthorizations(page) {
    const req = {
      userId: this.state.user,
      page: page,
      searchText: this.state.searchText
    };
    this.props.getAuths(req);
  }
  
  async submitAuthorization() {
    this.setState({ showLoader: true });
    try {
      const isValid = await this.isValid();
      if (isValid === true) {
        this.setState({ errors: {} });
        await this.props.addAuth({ ...this.state});
        ToastService.show('The authorization has been successfully added!', 'success');
        this.props.onHide();
        this.loadAuthorizations(1);
      }
      this.setState({ showLoader: false });
    } catch (error) {
      // handle error
      console.log('error', error.response);
      this.setState({ showLoader: false });
      ToastService.show(`${error.response.statusText},please contact customer support.`, 'error');
      this.props.onHide();
    }
    this.loadAuthorizations(1);
  }

  async isValid() {
    try {
      let { errors, isValid } = await validateAddAuthorization(this.state);
      if (!isValid) {
        this.setState({ errors });
      }
      return isValid;
    } catch (error) {}
  }

  showToast() {
    ToastService.show(
      'The authorization has been successfully added!',
      'success'
    );
  }

  render() {
    const loader = this.state.showLoader ? <Loader /> : null;
    return (
      <Modal
        className='px-5'
        show={this.props.show}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onHide={this.props.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <figure className='icon-group mt-3'>
              <i className='material-icons'>playlist_add</i>
              <h3 className='heading ml-3 mt-3'>Add Authorization</h3>
            </figure>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextFieldGroup
            label='First name'
            onChange={this.onChange}
            value={this.state.firstName}
            field='firstName'
            required={true}
            error={this.state.errors.firstName}
          />
          <TextFieldGroup
            label='Last name'
            onChange={this.onChange}
            value={this.state.lastName}
            field='lastName'
            required={true}
            error={this.state.errors.lastName}
          />
          <TextFieldGroup
            label='Date of Birth (Format: YYYY-MM-DD)'
            onChange={this.onChange}
            value={this.state.dob}
            field='dob'
            required={true}
            error={this.state.errors.dob}
          />
          <TextFieldGroup
            label='Email'
            onChange={this.onChange}
            value={this.state.email}
            field='email'
            required={true}
            error={this.state.errors.email}
          />
        </Modal.Body>
        <Modal.Footer>
          {loader}
        <Button onClick={this.submitAuthorization} className='primary mx-3 mt-3'><i className='material-icons'>playlist_add_check</i>Add Authorization</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapActionsToProps = {
  addAuth,
  getAuths
};

const mapStateToProps = state => {
  return {
    authList: state.authorizationReducer.authList
  };
};

export default connect(mapStateToProps, mapActionsToProps)(AddAuthorizationPopUp);
