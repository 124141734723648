import {
    ADD_AUTH_SUCCESS,
    GET_AUTHS_SUCCESS,
    GET_AUTHORIZED_AUTHS_SUCCESS,
    ADD_SUBJECT_AUTH_SUCCESS,
    GET_SUBJECT_AUTH_SUCCESS
  } from '../common/constants';
  export default (state = [], action) => {
    switch (action.type) {
      case ADD_AUTH_SUCCESS:
        return {
          authCreate: action.auth
        };
      case GET_AUTHS_SUCCESS:
        return {
          ...state,
          authList: action.auths
        };
      case GET_AUTHORIZED_AUTHS_SUCCESS:
        return {
          ...state,
          authorizedAuthList: action.auths
        };  
      case ADD_SUBJECT_AUTH_SUCCESS:
        return {
          subjectAuthCreate: action.subjectAuth
        };
      case GET_SUBJECT_AUTH_SUCCESS:
        return {
          ...state,
          subjectAuth: action.subjectAuth
        };    
      default:
        return state;
    }
  };
  