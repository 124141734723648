import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";

class FormsPage extends Component {
  render() {
    return (
        <Container fluid>
        <Row className='breadcrumb-section'>
          <Col lg={12}>
            <Breadcrumb className='mt-2'>
              <Breadcrumb.Item><Link to='/'>Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item active>Forms</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className='button-action intro-text text-center'>
          <Col lg={12}>
            <h1>Forms</h1>
            <h5 className='mb-5'>
            Here you will find all the necessary forms that you will need before screening.
            </h5>
          </Col>
        </Row>
        <Row className="table-section">
          <Col lg={12}>
            <section className="list-box mt-3">
              <Table responsive="xl" className="mb-5">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Format</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>A Summary of Your Rights Under the Fair Credit Reporting Act</td>
                    <td>pdf</td>
                    <td>
                      <Link to="/pdf/bcfp_consumer-rights-summary_2018-09.pdf" target="_blank" download>
                        <Button variant="outline-primary">
                          <i className="material-icons">cloud_download</i>
                          Download
                        </Button>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Remedying the Effects of Identity Theft</td>
                    <td>pdf</td>
                    <td>
                      <Link to="/pdf/Remedying the Effects of Identity Theft.pdf" target="_blank" download>
                        <Button variant="outline-primary">
                          <i className="material-icons">cloud_download</i>
                          Download
                        </Button>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Remedying the Effects of Identity Theft Spanish</td>
                    <td>pdf</td>
                    <td>
                      <Link to="/pdf/Remedying the Effects of Identity Theft Spanish.pdf" target="_blank" download>
                        <Button variant="outline-primary">
                          <i className="material-icons">cloud_download</i>
                          Download
                        </Button>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>California San Francisco OLSE Notice</td>
                    <td>pdf</td>
                    <td>
                      <Link to="/pdf/California San Francisco OLSE Notice.pdf" target="_blank" download>
                        <Button variant="outline-primary">
                          <i className="material-icons">cloud_download</i>
                          Download
                        </Button>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </section>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default FormsPage;
