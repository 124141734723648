import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from 'react-router-dom';

class SubjectsHistoryListPage extends Component {
  render() {
    return (
      <Container>
      <Row >
          <Col lg={12}>
            <Breadcrumb>
              <Breadcrumb.Item><Link to='/'>Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item><Link to='/individuals'>Individuals</Link></Breadcrumb.Item>
              <Breadcrumb.Item active>History List</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col lg={12}>
            <section className="mb-4">
              <h1>
                <i className="material-icons md-48">history</i>Individual's History
              </h1>
            </section>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <h5 className="box title-box-quaternary">
              List of All Background Checks
            </h5>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Order Number</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>06/22/2018 09:27</td>
                  <td>Number of the order</td>
                  <td>
                    <Button variant="primary" href="/history">
                      <i className="material-icons">pageview</i>
                      View Results
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>06/22/2018 09:27</td>
                  <td>Number of the order</td>
                  <td>
                    <Button variant="primary" href="/history">
                      <i className="material-icons">pageview</i>
                      View Results
                    </Button>
                  </td>
                </tr>
                <tr>
                  <td>06/22/2018 09:27</td>
                  <td>Number of the order</td>
                  <td>
                    <Button variant="primary" href="/history">
                      <i className="material-icons">pageview</i>
                      View Results
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default SubjectsHistoryListPage;
