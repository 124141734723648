import { combineReducers } from 'redux';
import userReducer from './userReducer';
import orderReducer from './orderReducer';
import subjectReducer from './subjectReducer';
import employeeReducer from './employeeReducer';
import authorizationReducer from './authorizationReducer';
import { connectRouter } from 'connected-react-router';

const createRootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    userReducer,
    orderReducer,
    subjectReducer,
    employeeReducer,
    authorizationReducer
  });

export default createRootReducer;
