import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import ReactPaginate from 'react-paginate';
import AdminViewList from '../../images/AdminViewList.png';
import { getSubjectsWithMonthlyOrders } from '../../actions/subjectActions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class SubjectsMonthlyListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ''
    };
    this.loadSubjectsWithMonthlyOrders = this.loadSubjectsWithMonthlyOrders.bind(
      this
    );
    this.handleChange = this.handleChange.bind(this);
  }

  showModal(subjectId) {
    this.setState({ showModal: true, subjectId: subjectId });
  }
  showMonthlyModal(subjectId) {
    this.setState({ showMonthlyModal: true, subjectId: subjectId });
  }
  componentDidMount() {
    this.loadSubjectsWithMonthlyOrders(1);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  loadSubjectsWithMonthlyOrders(page) {
    const req = {
      page: page,
      searchText: this.state.searchText
    };
    this.props.getSubjectsWithMonthlyOrders(req);
  }
  handlePageClick = e => {
    this.loadSubjectsWithMonthlyOrders(e.selected + 1);
  };
  render() {
    return (
      <Container fluid>
        <Row className='table-section'>
          <Col lg={12}>
            <figure className='icon-group mt-5'>
              <img src={AdminViewList} alt='Admin View List Icon' />
              <h2 className='heading ml-3'>
                Individual's PostHire Constant Screening
              </h2>
            </figure>
            <Form inline>
              <FormControl
                type='text'
                placeholder='Search Individuals'
                className='mr-sm-2'
                name='searchText'
                onChange={this.handleChange}
              />
              <Button
                variant='outline-dark mb-3 mt-3'
                onClick={() => this.loadSubjectsWithMonthlyOrders(1)}
              >
                Find
              </Button>
            </Form>
            <section className='list-box mt-3'>
              <Table responsive='xl' className='mb-5'>
                <thead>
                  <tr>
                    <th>Profile Details</th>
                    <th>Full Name</th>
                    <th>Date Of Birth</th>
                    <th>Location</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.monthlySubjectsList
                    ? this.props.monthlySubjectsList.rows.map(i => {
                        return (
                          <tr>
                            <td>
                              <Link
                                to={{
                                  pathname: '/view-subject',
                                  state: { subjectId: i.Subject.subject_id }
                                }}
                              >
                                <Button variant='link'>
                                  <i className='material-icons'>visibility</i>
                                  View Profile
                                </Button>
                              </Link>
                            </td>
                            <td>
                              {i.Subject.name_first} {i.Subject.name_middle ? i.Subject.name_middle : '' }{' '}
                              {i.Subject.name_last}
                            </td>
                            <td>{i.Subject.dob}</td>
                            <td>
                              {i.Subject.state},{i.Subject.county},
                              {i.Subject.city}
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={'< Previous'}
                nextLabel={'Next >'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={
                  this.props.monthlySubjectsList
                    ? Math.ceil(this.props.monthlySubjectsList.length / 10)
                    : 1
                }
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={'list-group-pagination pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                disabledClassName={'page-item disabled'}
                activeClassName={'page-item active'}
              />
            </section>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapActionsToProps = {
  getSubjectsWithMonthlyOrders
};

const mapStateToProps = state => {
  return {
    monthlySubjectsList: state.subjectReducer.subjectsWithMonthly
  };
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(SubjectsMonthlyListPage);
