import React, { Component } from 'react';
import Toast from 'react-bootstrap/Toast';
import PersonnelgraphIcon from '../../images/Personnelgraph-symbol.png';
import PubSub from 'pubsub-js';

class ToastsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      type: '',
      show: false
    }
  }

  componentDidMount() {
    let self = this;
    PubSub.subscribe('toast', function(msg, data){
      self.setState({
        text: data.text,
        type: data.type,
        show: true
      });
    });
  }

  render() {
    const error = this.state.type==='error'?<span style={{color: 'red'}}>{this.state.text} </span>:null;
    return (
        <div
          style={{
            position: 'fixed',
            top: 100,
            right: 5
          }}
        >
          <Toast onClose={() => this.setState({show:false})} show={this.state.show} delay={3000} autohide>
            <Toast.Header>
              <img
                src={PersonnelgraphIcon}
                alt="Personnelgraph Symbol"
                className="rounded mr-2"
              />
              <strong className="mr-auto">Personnelgraph</strong>
              <small>Just now</small>
            </Toast.Header>
            <Toast.Body>{error || this.state.text}</Toast.Body>
          </Toast>
        </div>
    );
  }
}

export default ToastsPage;
