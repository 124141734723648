import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

export default function validateAddSubject(data) {
 
  let errors = {};
 

  
  if (Validator.isEmpty(data.firstName)) {
    errors.firstName = 'First name is required';
  }

  if (!Validator.isEmpty(data.ssn) && !/^\d{3}-\d{2}-\d{4}$/.test(data.ssn)) {
    errors.ssn = 'Incorrect SSN format';
  }
  if (!Validator.isEmpty(data.address1) && data.address1.length > 64) {
    errors.address1 = 'Address should be max of 64 characters';
  }

  if (!Validator.isEmpty(data.address2) && data.address2.length > 64) {
    errors.address2 = 'Address should be max of 64 characters';
  }

  if (!Validator.isEmpty(data.email) && !Validator.isEmail(data.email)) {
    errors.email = 'Invalid email format';
  }

  if (!Validator.isEmpty(data.zip) && data.zip.length > 5) {
    errors.zip = 'Zip should be max of 5 characters';
  }

  if (Validator.isEmpty(data.lastName)) {
    errors.lastName = 'Last name is required';
  }
  if (Validator.isEmpty(data.gender)) {
    errors.gender = 'Gender is required';
  }

  if (Validator.isEmpty(data.dob)) {
    errors.dob = 'Date of birth is required';
  }

  if (moment(data.dob).format('YYYY-MM-DD') !== data.dob) {
    errors.dob = 'Date should be in format YYYY-MM-DD';
  }

  if (Validator.isEmpty(data.state)) {
    errors.state = 'Please select state';
  }
  if (Validator.isEmpty(data.county)) {
    errors.county = 'Please select county';
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
}
