import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';

class Charges extends Component {
  render() {
    return this.props.charges.map((c,i) => {
      return (
        <div key = {i}>
          <Row>
            <Col lg={6}>
              <article className='results-component order-info mt-3 mb-5'>
                <ListGroup variant='flush'>
                  <h5 className='ml-3 mb-4 mt-3'>Charge</h5>
                  <ListGroup.Item>
                    Charge:
                    <span className='pl-2'>{c.charge}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Charge Level:
                    <span className='pl-2'>
                      {c.charge_level}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Disposition Date:
                    <span className='pl-2'>
                      {c.disposition_date}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Disposition:
                    <span className='pl-2'>
                      {c.disposition}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Alternative Adjudication:
                    <span className='pl-2' />
                    {c.charge}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Hearing Date:
                    <span className='pl-2'>
                      {c.hearing_date}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Hearing Type:
                    <span className='pl-2'>
                      {c.hearing_type}
                    </span>
                  </ListGroup.Item>
                </ListGroup>
              </article>
            </Col>
            <Col lg={6}>
              <article className='results-component subject-info mt-3 mb-5'>
                <ListGroup variant='flush'>
                  <h5 className='ml-3 mb-4 mt-3'>Sentencing</h5>
                  <ListGroup.Item>
                    Jail Time:
                    <span className='pl-2'>{c.jail_time}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Prison Time:
                    <span className='pl-2'>
                      {c.prison_time}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Probation:
                    <span className='pl-2'>{c.probation}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Fines:
                    <span className='pl-2'>{c.fines}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Fines Paid Date:
                    <span className='pl-2'>
                      {c.fines_paid_date}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Cost:<span className='pl-2'>{c.cost}</span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Restitution:
                    <span className='pl-2'>
                      {c.restitution}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Active Warrant:
                    <span className='pl-2'>
                      {c.active_warrant}
                    </span>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Other:
                    <span className='pl-2'>{c.other}</span>
                  </ListGroup.Item>
                </ListGroup>
              </article>
            </Col>
          </Row>
        </div>
      );
    });
  }
}

export default Charges;
