import React, { Component } from 'react';
import validateUserProfileInput from '../../validations/userProfileValidation';
import TextFieldGroup from '../common/textFieldGroup';
import { getUserWithJwt, updateUser } from '../../actions/userActions';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ToastService from '../Toasts/toastService';

class UserProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      fullName: '',
      phone: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      errors: {},
      isValid: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getUserWithJwt();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.user) {
      this.setState({
        id: newProps.user.Id,
        fullName: newProps.user.fullName,
        phone: newProps.user.phone,
        email: newProps.user.email,
        creditCard: newProps.user.creditCard
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  isValid() {
    let differentEmail =
      (this.props.user && this.props.user.email !== this.state.email) ||
      (this.props.result && this.props.result.user.email !== this.state.email)
        ? true
        : false;
    let { errors, isValid } = validateUserProfileInput(
      this.state,
      differentEmail
    );

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.isValid()) {
      this.props.updateUser(this.state);
      this.setState({
        password: '',
        passwordConfirmation: '',
        errors : {}
      });
      ToastService.show('Successfully updated!', 'success');
    }
  }

  showToast() {
    ToastService.show('Successfully updated!', 'success');
  }

  render() {
    const { errors } = this.state;

    return (
      <Row>
        <Col lg={12}>
          <Card bg='secondary' text='white' className='card-box-dark mt-5'>
            <form onSubmit={this.onSubmit}>
              <Card.Body>
                <Card.Title>
                  <h2>
                    <i className='material-icons md-36'>account_circle</i>
                    Profile
                  </h2>
                </Card.Title>
                <h5 className='mt-5 mb-4'>Personal Information</h5>
                <TextFieldGroup
                  error={errors.fullName}
                  label='Full Name'
                  onChange={this.onChange}
                  value={this.state.fullName}
                  field='fullName'
                />
                <TextFieldGroup
                  error={errors.password}
                  label='Password'
                  onChange={this.onChange}
                  value={this.state.password}
                  field='password'
                  type='password'
                />
                <TextFieldGroup
                  error={errors.passwordConfirmation}
                  label='Confirm password'
                  onChange={this.onChange}
                  value={this.state.passwordConfirmation}
                  field='passwordConfirmation'
                  type='password'
                />

                <TextFieldGroup
                  error={errors.email}
                  label='Email'
                  onChange={this.onChange}
                  value={this.state.email}
                  field='email'
                  type='email'
                />
                <TextFieldGroup
                  error={errors.phone}
                  label='Phone Number'
                  onChange={this.onChange}
                  value={this.state.phone}
                  field='phone'
                />
                <Button
                  variant='primary'
                  className='mt-4 mb-3'
                  onClick={this.onSubmit}
                >
                  <i className='material-icons'>loop</i>
                  Update Profile
                </Button>
              </Card.Body>
            </form>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapActionsToProps = {
  getUserWithJwt,
  updateUser
};

const mapStateToProps = state => {
  return {
    user: state.userReducer.user
  };
};

export default connect(mapStateToProps, mapActionsToProps)(UserProfilePage);
