import React, { Component } from 'react';
import CaseDetails from './caseDetails';
import Charges from './charges';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
class Case extends Component {  
  render() {
    return this.props.details.map(c => {
      return (
        <div key={c.case_number}>
          <CaseDetails caseDetails={c} />
          <Row>
            <Col lg={12}>
              <h5 className='text-center box title-box-tertiary'>Charges</h5>
            </Col>
          </Row>
          <Charges charges={c.charges} />
        </div>
      );
    });
  }
}

export default Case;
