import React from 'react';
import classnames from 'classnames';

const SelectFieldGroup = ({ value, label, error, onChange , required , name }) => {
  return (
    <div className={classnames('form-group', { 'has-error': error })}>
      <label className="control-label">{label} {required ? <span className="text-danger"> *</span> : null}</label>
      <select onChange={onChange} className="form-control" value={value} name = {name}>
        <option value="" disabled>- - Select gender - -</option>
        <option value="F">Female</option>
        <option value="M">Male</option>
      </select>
      {error ? <span className="text-danger">{error}</span> : null}
    </div>
  );
};

export default SelectFieldGroup;
