import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { getUserCards } from '../../actions/userActions';
import { connect } from 'react-redux';
import charges from '../../static/charges.json';
import OneTimeIcon from '../../images/OneTimeIcon.png';
import { runOneTimeCheck } from '../../actions/orderActions';
import config from '../../common/config';
import enums from '../../common/enums';
import ToastService from '../Toasts/toastService';
import ApiHelper from '../../api/apiCallsHelper.js';
import { getAuthorizedAuths, addSubjectAuth } from '../../actions/authorizationActions';
import UserApi from '../../api/user-api';

class OneTimeCheckPopUp extends Component {
  constructor(props) {
    super(props);
    this.submitOrder = this.submitOrder.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeAuth = this.handleChangeAuth.bind(this);
    this.getServiceIdAndFees = this.getServiceIdAndFees.bind(this);
    this.state = {
      userId: '',
      cardToken: '',
      courtFee: 0,
      serviceType: process.env.REACT_APP_TRUE_FINGERPRINTS_COUNTY_SERVICE_ID,
      error: '',
      orders: '',
      authorization: ''
    };
  }

  getServiceIdAndFees(state, county) {
    let serviceTypeId = 0,
      courtFee = 0;
    if (state && county) {
      const subjectState = charges[state.split('-').pop()];
      serviceTypeId =
        subjectState['SearchType'] === 'County'
          ? process.env.REACT_APP_TRUE_FINGERPRINTS_COUNTY_SERVICE_ID
          : process.env.REACT_APP_TRUE_FINGERPRINTS_STATEWIDE_SERVICE_ID;

      const surcharge = subjectState['Surcharge'];
      courtFee = 0;
      if (surcharge === 'Yes') {
        const surchargeDetails = subjectState['Surcharge Details'];
        const surchargeDetails2 = subjectState['Surcharge Details 2'];

        if (surchargeDetails['All']) {
          courtFee = surchargeDetails['All'];
        } else if (surchargeDetails[county]) {
          courtFee = surchargeDetails[county];
        } else if (surchargeDetails2 && surchargeDetails2[county]) {
          courtFee = surchargeDetails2[county];
        }
      } else {
        courtFee = 0;
      }
    }
    this.setState({ serviceType: serviceTypeId, courtFee: courtFee });
  }

  async componentDidMount() {
    let user = await UserApi.getUserWithJwt();
    if (user) {
      this.setState({
        user: user
      });
    }

    const req = {
      userId: this.state.user.Id
    }
    this.props.getAuthorizedAuths(req);

    try {
      let orders = await ApiHelper.postWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/orders/orders-limit`);

      this.setState({
        orders: orders.data.count
      });

      if (this.props.subjectDetails) {
        this.props.getSubjectDetails();
      }

      if (!this.props.cards) {
        this.props.getUserCards();
      }

      this.getServiceIdAndFees(this.props.state, this.props.county);
    } catch (err) { }
  }

  handleChange(e) {
    this.setState({ cardToken: e.target.value });
  }

  handleChangeAuth(e) {
    this.setState({ authorization: e.target.value});
  }

  async submitOrder() {
    try {
      if (this.state.cardToken === '') {
        this.setState({ error: 'You must select a card' });
        return false;
      }

      if (this.props.user.preHireCharge != null) {
        const req = {
          amount: (parseInt(this.props.user.preHireCharge, 10) + this.state.courtFee) * 100,
          subject_id: this.props.subjectId,
          source: this.state.cardToken,
          service_id: this.state.serviceType,
          orderTypeId: enums.OrderTypes.oneTimeCheck,
          auth: this.state.authorization
        };
        await this.props.runOneTimeCheck(req);
        //store auth for subject
        const data = {
          userId: this.state.user.Id,
          auth: this.state.authorization,
          subject_id: this.props.subjectId,
        }
        this.props.addSubjectAuth(data);

      } else {
        const req = {
          amount: (config.OrderFees.OneTimeCheck + this.state.courtFee) * 100,
          subject_id: this.props.subjectId,
          source: this.state.cardToken,
          service_id: this.state.serviceType,
          orderTypeId: enums.OrderTypes.oneTimeCheck,
          auth: this.state.authorization
        };
        await this.props.runOneTimeCheck(req);
        //store auth for subject
        const data = {
          userId: this.state.user.Id,
          auth: this.state.authorization,
          subject_id: this.props.subjectId,
        }
        this.props.addSubjectAuth(data);
      }

      if (
        this.props.oneTimeCheckResult &&
        this.props.oneTimeCheckResult.status === 201
      ) {
        ToastService.show(
          'The order has been successfully sent for processing!',
          'success'
        );
        this.props.loadSubjects();
      }
      this.props.onHide();
    } catch (err) {
      if (err.response && err.response.status === 422) {
        ToastService.show(
          'PreHire is already in process for this subject',
          'error'
        );
        this.props.onHide();
      } else {
        //handle exception
        console.log('error', err.response);
        ToastService.show('Something went wrong, please contact customer support.', 'error');
      }
    }
  }

  showToast() {
    ToastService.show(
      'The order has been successfully sent for processing!',
      'success'
    );
  }

  render() {
    let button = null;
    let text = null;
    if ((parseInt(this.state.orders, 10) < parseInt(this.props.user.preHireLimit, 10)) || ((this.props.user.preHireLimit) === null)) {
      button = <Button onClick={this.submitOrder} className='primary mx-3 mt-3'><i className='material-icons'>fingerprint</i>Run PreHire Screening</Button>;
    } else {
      button = <small className='text-danger'>Account Limited - This account does not have an active plan and can not issue PreHire or PostHire checks. For new accounts, you must go through compliance review and then activate a plan. If you previously had an active plan, you've reached your limit. Please contact Personnelgraph support.</small>
    }
    return (
      <Modal
        className='px-5'
        show={this.props.show}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onHide={this.props.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <figure className='icon-group mt-3'>
              <img src={OneTimeIcon} alt='One-Time Check Icon' />
              <h3 className='heading ml-3 mt-3'>Initiate PreHire Screening</h3>
            </figure>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='mb-4 mt-2'>Please select your authorization method and your billing method:</h4>
          <Form.Group controlId='exampleForm.ControlSelect11'>
          <Form.Label>Authorization</Form.Label>
          <Form.Control as='select' onChange={this.handleChangeAuth}>
          <option value=''>Manual</option>
            {this.props.authorizedAuths && this.props.authorizedAuths.length > 0
              ? this.props.authorizedAuths.map(a => {
                return (
                  <option key={a.unique} value={a.unique}>
                    {a.firstName} {a.lastName} - {a.unique}
                  </option>
                );
              })
              :
              null}
            </Form.Control>
            {this.state.authorization === "" ?
               <small className='text-primary w-75'>
                Notice: You have selected “Manual” for your FCRA authorization method. Use this method only if you would like to complete the authorization manually. This process requires you to print the forms from the “Forms” menu and have the individual physically sign them. We highly recommend using the automated FCRA authorization system via the “Authorization” menu above. 
              </small>
              :
              null}
          </Form.Group>
          <Form.Group controlId='exampleForm.ControlSelect1'>
            <Form.Label>Credit Card</Form.Label>
            <Form.Control as='select' onChange={this.handleChange}>
              <option value=''>Select Card...</option>
              {this.props.cards && this.props.cards.length > 0
                ? this.props.cards.map(r => {
                  return (
                    <option key={r.id} value={r.id}>
                      {r.brand} - {r.last4}
                    </option>
                  );
                })
                : null}
            </Form.Control>            
            {this.state.error ? <p className='text-danger'>{this.state.error}</p> : null}
            <Form.Label className='mt-5'>
              {' '}
              Court Fee Surcharge: 
                ${this.state.courtFee}
            </Form.Label>
            {/*}  <br />
            <Form.Label>
              {' '}
              PreHire Fee $: {config.OrderFees.OneTimeCheck}
            </Form.Label>*/}
            <br />
            <Form.Label>
              PreHire Charge: 
                ${this.props.user.preHireCharge}
            </Form.Label>
            <br />
            <Form.Label>
              {' '}
              Total Charge: 
                ${this.state.courtFee + parseInt(this.props.user.preHireCharge, 10)}
            </Form.Label>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {text}
          {button}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapActionsToProps = {
  getUserCards: getUserCards,
  runOneTimeCheck: runOneTimeCheck,
  getAuthorizedAuths: getAuthorizedAuths,
  addSubjectAuth: addSubjectAuth
};

const mapStateToProps = state => {
  return {
    cards: state.userReducer.cards,
    oneTimeCheckResult: state.orderReducer.oneTimeCheckResult,
    authorizedAuths: state.authorizationReducer.authorizedAuthList
  };
};

export default connect(mapStateToProps, mapActionsToProps)(OneTimeCheckPopUp);
