import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import EmployeesIcon from '../../../images/EmployeesIcon.png';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import { getEmployees } from '../../../actions/employeeActions';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import apiCallsHelper from '../../../api/apiCallsHelper';
import config from '../../../common/config';
import ReactPaginate from 'react-paginate';

class EmployeesListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      searchText: ''
    };
    this.loadEmployees = this.loadEmployees.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  componentDidMount() {
    this.loadEmployees(1);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handlePageClick = e => {
    this.loadEmployees(e.selected + 1);
  };
  loadEmployees(page) {
    const req = {
      page: page,
      searchText: this.state.searchText
    };
    this.props.getEmployees(req);
  }

  async handleClickDelete(Id) {
    try {
      const employee = {
        employeeId: Id
      };

      const result = await apiCallsHelper.postWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/employees/delete`,
        employee
      );
      if (result.status === 200) {
        this.props.getEmployees();
      }
    } catch (error) {
      // Throw error
    }
  }
  componentWillMount() {
    if (localStorage.getItem('userRole') && localStorage.getItem('userRole') !== 'sysadmin') {
      window.location.href = '/error';
    }
    else if (sessionStorage.getItem('userRole') && sessionStorage.getItem('userRole') !== 'sysadmin') {
      window.location.href = '/error';
    }
  }

  submit = (employeeId, employeeName) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Remove Employee?</h1>
            <p>Are you sure you want to remove {employeeName}? </p>
            <Button variant='outline-secondary mt-3' onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant='danger ml-4 mt-3'
              onClick={() => {
                this.handleClickDelete(employeeId);
                onClose();
              }}
            >
              Yes, Remove it!
            </Button>
          </div>
        );
      }
    });
  };

  render() {
    return (
      <Container fluid>
        <Row className='breadcrumb-section'>
          <Col lg={12}>
            <Breadcrumb className='mt-2'>
              <Breadcrumb.Item>
                <Link to='/'>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Employees</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className='button-action intro-text text-center mb-5'>
          <Col lg={12}>
            <h1>Add Employee</h1>
            <h5 className='mt-4 mb-5'>
              This is the page where you can find all added employees of True
              Fingerprints. Employees run checks for individuals that are on a
              monthly check list.
            </h5>

            <Link to='/add-employee'>
              <Button variant='success' size='lg'>
                <i className='material-icons'>person_add</i>Add Employee To The
                List
              </Button>
            </Link>
          </Col>
        </Row>

        <Row className='table-section'>
          <Col lg={12}>
            <figure className='icon-group mt-5'>
              <img src={EmployeesIcon} alt='Employees List Icon' />
              <h2 className='heading ml-3'>Employees List</h2>
            </figure>
            <Form inline>
              <FormControl
                type='text'
                placeholder='Search for Employee'
                className='mr-sm-2'
                name='searchText'
                onChange = {this.handleChange}
              />
              <Button variant='outline-dark mb-3 mt-3' onClick={()=> this.loadEmployees(1)}>Find</Button>
            </Form>
            <section className='list-box mt-3'>
              <Table responsive='xl' className='mb-5'>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email Address</th>
                    <th>Phone Number</th>
                    <th colSpan='2'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.employeesList
                    ? this.props.employeesList.rows.map(i => {
                        return (
                          <tr key={i.Id}>
                            <td>{i.fullName}</td>
                            <td>{i.email}</td>
                            <td>{i.phone}</td>
                            <td>
                              <Link
                                to={{
                                  pathname: '/editEmployee',
                                  state: {
                                    employeeId: i.Id
                                  }
                                }}
                              >
                                <Button variant='outline-primary'>
                                  <i className='material-icons'>edit</i>
                                  Edit
                                </Button>
                              </Link>
                            </td>
                            <td>
                              <Button
                                variant='outline-danger'
                                onClick={() => this.submit(i.Id, i.fullName)}
                              >
                                {' '}
                                <i className='material-icons'>delete</i> Remove
                              </Button>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={'< Previous'}
                nextLabel={'Next >'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={
                  this.props.subjectsList
                    ? Math.ceil(this.props.subjectsList.count / 10)
                    : 1
                }
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={'list-group-pagination pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                disabledClassName={'page-item disabled'}
                activeClassName={'page-item active'}
              />
            </section>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapActionsToProps = {
  getEmployees: getEmployees
};

const mapStateToProps = state => {
  return {
    employeesList: state.employeeReducer.employeesList
  };
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(EmployeesListPage);
