import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import ApiHelper from '../../api/apiCallsHelper.js';
import { Link } from 'react-router-dom';
import PubSub from 'pubsub-js';
import config from '../../common/config';

class NotificationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: []
    };

    this.seenNotification = this.seenNotification.bind(this);
  }
  async componentDidMount() {
    try {
      let notifications = await ApiHelper.getWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/notifications/get`
      );

      this.setState({
        notifications: notifications
      });
    } catch (e) { }
  }

  seenNotification(notification) {
    try {
      let self = this;
      ApiHelper.putWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/notifications/update`,
        { id: notification.id, seen: true }
      ).then(response => {
        let nots = self.state.notifications;
        nots.map(n => {
          if (n.id === notification.id) n.seen = true;
          return n;
        });
        self.setState({
          notifications: nots
        });
      }).catch(e => { });

      PubSub.publish('change_notification', { action: 'plus' });
    } catch (e) { }
  }

  render() {
    return (
      <Container>
        <Row>
          <Col lg={12}>
            {this.state.notifications.map(notification => {
              let className = 'material-icons clear-colorize';
              let icon = 'check_circle';
              if (notification.notificationType === '3') {
                className = 'material-icons alert-colorize';
                icon = 'warning';
              } else if (notification.notificationType === '2') {
                className = 'material-icons awaiting-colorize';
                icon = 'hourglass_full';
              } else if (notification.notificationType === '4') {
                className = 'material-icons text-danger';
                icon = 'error';
              } else {
                className = 'material-icons clear-colorize';
                icon = 'check_circle';
              }
              return (
                <Alert
                  variant='light text-dark mt-5'
                  key={notification.id}
                  name={notification.notificationType}
                  style={{ opacity: notification.seen ? 0.4 : 1 }}
                >
                  <Alert.Heading>
                    {/*} <i className={className}>{icon}</i>*/}
                  </Alert.Heading>
                  <p>{notification.notification}</p>
                  <hr />
                  {notification.order_id !== 0 ? (
                    <Link
                      to={{
                        pathname: '/history',
                        state: {
                          currentOrderId: notification.order_id,
                          currentSubjectId: notification.subject_id
                        }
                      }}
                    >
                      <Button
                        variant='primary'
                        onClick={() => this.seenNotification(notification)}
                      >
                        <i className='material-icons'>pageview</i>View Results
                      </Button>
                    </Link>
                  ) : null}
                </Alert>
              );
            })}
            {/* <Alert variant='danger text-danger mt-3'>
              <Alert.Heading>
                <i className='material-icons danger'>error</i>Maria Smith
                Robertson
              </Alert.Heading>
              <p>
                Appropriately disseminate robust infrastructures rather than
                installed base innovation. Objectively extend.
              </p>
              <hr />

              <Button
                variant='danger'
                className='btn-support'
                href='tel:+123456890'
              >
                <i className='material-icons'>phone</i>Call Support
              </Button>
              <Button
                variant='danger'
                className='btn-support'
                href='mailto:support@personnelgraph.net'
              >
                <i className='material-icons'>mail</i>Mail Support
              </Button>
            </Alert> */}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default NotificationsPage;
