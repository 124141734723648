import {
    ADD_AUTH_SUCCESS,
    GET_AUTHS_SUCCESS,
    GET_AUTHORIZED_AUTHS_SUCCESS,
    ADD_SUBJECT_AUTH_SUCCESS,
    GET_SUBJECT_AUTH_SUCCESS
  } from '../common/constants';
  import auhtorizationApi from '../api/authorization-api';
  import apiCallsHelper from '../api/apiCallsHelper';
  import config from '../common/config';
  
  export function addAuthSuccess(auth) {
    return {
      type: ADD_AUTH_SUCCESS,
      auth
    };
  }
    
  export function getAuthsSuccess(auths) {
    return {
      type: GET_AUTHS_SUCCESS,
      auths
    };
  }

  export function getAuthorizedAuthsSuccess(auths) {
    return {
      type: GET_AUTHORIZED_AUTHS_SUCCESS,
      auths
    };
  }

  export function addSubjectAuthSuccess(subjectAuth) {
    return {
      type: ADD_SUBJECT_AUTH_SUCCESS,
      subjectAuth
    };
  }
  
  export function getSubjectAuthsSuccess(subjectAuth) {
    return {
      type: GET_SUBJECT_AUTH_SUCCESS,
      subjectAuth
    };
  }

  export function addAuth(auth) {
    return function (dispatch) {
      return auhtorizationApi
        .addAuth(auth)
        .then(r => {
          if (r.user) {
            dispatch(addAuthSuccess(r));
            window.location.href = '/authorization';
          } else {
            console.log(r);
          }
        })
        .catch(err => {
          console.log('err', err);
          throw err;
        });
    };
  }

  export function getAuths(req) {
    return function (dispatch) {
      return apiCallsHelper
        .postWithJWT(`${config.trueFingerPrintsAPI.baseURL}/authorization/all`, req)
        .then(r => {
          dispatch(getAuthsSuccess(r.data));
        })
        .catch(err => {
          throw err;
        });
    };
  }

    export function getAuthorizedAuths(req) {
      return function (dispatch) {
        return apiCallsHelper
          .postWithJWT(`${config.trueFingerPrintsAPI.baseURL}/authorization/authorized`, req)
          .then(r => {
            dispatch(getAuthorizedAuthsSuccess(r.data));
          })
          .catch(err => {
            throw err;
          });
      };
  }
  
  export function addSubjectAuth(subjectAuth) {
    return function (dispatch) {
      return auhtorizationApi
        .addSubjectAuth(subjectAuth)
        .then(r => {
          if (r.user) {
            dispatch(addSubjectAuthSuccess(r));
            window.location.href = '/authorization';
          } else {
            console.log(r);
          }
        })
        .catch(err => {
          console.log('err', err);
          throw err;
        });
    };
  }

  export function getSubjectAuths(subjectId) {
    return function (dispatch) {
      return apiCallsHelper
        .getWithJWT(`${config.trueFingerPrintsAPI.baseURL}/authorization/get-subject-auth/${subjectId}`)
        .then(r => {
          dispatch(getSubjectAuthsSuccess(r.authorization));
        })
        .catch(err => {
          throw err;
        });
    };
  }
