import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Loader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Container fluid>
        <Row>
          <Col
            lg={12}
            className='text-group mt-5 px-5 d-flex justify-content-center align-items-center'
          >
            <Spinner animation='border' variant='success' role='status'>
              <span className='sr-only'>Loading...</span>
            </Spinner>
            <span className='info-text pl-3'>
              {this.props.text ? this.props.text : 'Loading...'}
            </span>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Loader;
