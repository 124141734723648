import React from "react";
import Col from "react-bootstrap/Col";

export default class Footer extends React.Component {
  render() {
    return (
      <footer className="text-center">
        <Col lg={12}>
          <p className="mt-5 mb-5">Personnelgraph LLC - Copyright 2019</p>
        </Col>
      </footer>
    );
  }
}
