import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import Case from '../Cases/case';
import { getOrderDetails } from '../../actions/orderActions';
import { getSubject } from '../../actions/subjectActions';
import { connect } from 'react-redux';

class SubjectsHistoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order_id: '',
      subject_id: ''
    };
  }
  componentDidMount() {
    if (this.props.currentOrderId && this.props.currentSubjectId) {
      this.props.getOrderDetails(this.props.currentOrderId);
      this.props.getSubject(this.props.currentSubjectId);
    } else if (
      this.props.location.state.currentOrderId &&
      this.props.location.state.currentSubjectId
    ) {
      this.props.getOrderDetails(this.props.location.state.currentOrderId);
      this.props.getSubject(this.props.location.state.currentSubjectId);
    }
  }
  render() {
    return (
      <Container>
        <Row>
          <Col lg={12}>
            <section className='subject-full-name mt-5'>
              <h1>
                {this.props.subject ? this.props.subject.name_first : null}{' '}
                {this.props.subject && this.props.subject.name_middle
                  ? this.props.subject.name_middle
                  : null}{' '}
                {this.props.subject ? this.props.subject.name_last : null}
              </h1>
            </section>
          </Col>
        </Row>

        {(localStorage.getItem('userRole') &&
          localStorage.getItem('userRole') !== 'user') ||
          (sessionStorage.getItem('userRole') &&
            sessionStorage.getItem('userRole') !== 'user') ? (
            <Row className='mt-4'>
              <Col lg={6}>
                <article className='results-component header-info'>
                  <ListGroup variant=''>
                    <ListGroup.Item>
                      Status:
                    <span className='pl-2'>
                        {this.props.details ? this.props.details.status : null}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Age:<span className='pl-2'>0.00s</span>
                    </ListGroup.Item>
                  </ListGroup>
                </article>
              </Col>
              <Col lg={6}>
                <article className='results-component header-info'>
                  <ListGroup variant='flush'>
                    <ListGroup.Item>
                      Order type:
                    <span className='pl-2'>
                        {this.props.details
                          ? this.props.details.order_type
                          : null}
                      </span>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      Service name:
                    <span className='pl-2'>
                        {this.props.details
                          ? this.props.details.service_name
                          : null}
                      </span>
                    </ListGroup.Item>
                  </ListGroup>
                </article>
              </Col>
            </Row>
          ) : null}

        <Row className='mt-5'>
          <Col lg={6}>
            <article className='results-component order-info mb-5'>
              <ListGroup variant='flush'>
                <h5 className='ml-3 mb-4 mt-3'>Order Information</h5>
                <ListGroup.Item>
                  Order ID:
                  <span className='pl-2'>
                    {this.props.details ? this.props.details.order_id : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Service:
                  <span className='pl-2'>
                    {this.props.details
                      ? this.props.details.service_name
                      : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  State:
                  <span className='pl-2'>
                    {this.props.details && this.props.details.state
                      ? this.props.details.state
                      : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  County:
                  <span className='pl-2'>
                    {this.props.details && this.props.details.county
                      ? this.props.details.county
                      : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Scope of Search:
                  <span className='pl-2'>
                    {this.props.details ? this.props.details.order_type : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Created:
                  <span className='pl-2'>
                    {this.props.details && this.props.details.created_date
                      ? this.props.details.created_date
                      : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Completed:
                  <span className='pl-2'>
                    {this.props.details
                      ? this.props.details.completed_date
                      : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Cases:
                  <span className='pl-2'>
                    {' '}
                    {this.props.details &&
                      this.props.details.cases &&
                      this.props.details.cases.length > 0
                      ? this.props.details.cases.length
                      : 'No cases have been found'}
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </article>
          </Col>
          <Col lg={6}>
            <article className='results-component subject-info mb-5'>
              <ListGroup variant='flush'>
                <h5 className='ml-3 mb-4 mt-3'>Individual Information</h5>
                <ListGroup.Item>
                  First Name:
                  <span className='pl-2'>
                    {' '}
                    {this.props.subject
                      ? this.props.subject.name_first
                      : null}{' '}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Last Name:
                  <span className='pl-2'>
                    {' '}
                    {this.props.subject ? this.props.subject.name_last : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  DOB:
                  <span className='pl-2'>
                    {' '}
                    {this.props.subject ? this.props.subject.dob : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  SSN:
                  <span className='pl-2'>
                    {' '}
                    {this.props.subject ? this.props.subject.ssn : null}
                  </span>
                </ListGroup.Item>

              </ListGroup>
            </article>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <h3 className='text-center box title-box-primary'>Cases</h3>
          </Col>
        </Row>
        {this.props.details && this.props.details.cases ? (
          <Case details={this.props.details.cases} />
        ) : null}

        {(localStorage.getItem('userRole') &&
          localStorage.getItem('userRole') !== 'user') ||
          (sessionStorage.getItem('userRole') &&
            sessionStorage.getItem('userRole') !== 'user') ? (
            <Row>
              <Col lg={12}>
                <h5 className='text-center box title-box-quaternary'>Notes</h5>
              </Col>
            </Row>
          ) : null}

        {(localStorage.getItem('userRole') &&
          localStorage.getItem('userRole') !== 'user') ||
          (sessionStorage.getItem('userRole') &&
            sessionStorage.getItem('userRole') !== 'user') ? (
            <Row>
              <Col lg={12}>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Note type</th>
                      <th>Note</th>
                      <th>Note Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.details && this.props.details.notes
                      ? this.props.details.notes.map(n => {
                        return (
                          <tr key={n.note_id}>
                            <td>{n.note_type}</td>
                            <td>{n.note}</td>
                            <td>{n.timestamp}</td>
                          </tr>
                        );
                      })
                      : null}
                  </tbody>
                </Table>
              </Col>
            </Row>
          ) : null}
      </Container>
    );
  }
}

const mapActionsToProps = {
  getOrderDetails: getOrderDetails,
  getSubject: getSubject
};

const mapStateToProps = state => {
  return {
    details: state.orderReducer.details,
    subject: state.subjectReducer.subject,
    currentOrderId: state.orderReducer.currentOrderId,
    currentSubjectId: state.subjectReducer.currentSubjectId
  };
};

export default connect(mapStateToProps, mapActionsToProps)(SubjectsHistoryPage);
