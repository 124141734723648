import axios from 'axios';
import config from '../common/config';

class AuthorizationApi {
    addAuth(auth) {
    const token = localStorage.getItem('jwt')
      ? localStorage.getItem('jwt')
      : sessionStorage.getItem('jwt');

    const configAPi = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    return axios
      .post(
        `${config.trueFingerPrintsAPI.baseURL}/authorization`,
        {
          userId: auth.user,
          firstName: auth.firstName,
          lastName: auth.lastName,
          dob: auth.dob,
          email: auth.email
        },
        configAPi
      )
      .then(r => {
        return r.data;
      })
      .catch(err => {
        return err;
      });
  }

  addSubjectAuth(subjectAuth) {
    console.log(subjectAuth)
    const token = localStorage.getItem('jwt')
      ? localStorage.getItem('jwt')
      : sessionStorage.getItem('jwt');

    const configAPi = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    return axios
      .post(
        `${config.trueFingerPrintsAPI.baseURL}/authorization/add-subject-auth`,
        {
          userId: subjectAuth.user,
          auth: subjectAuth.auth,
          subject_id: subjectAuth.subject_id
        },
        configAPi
      )
      .then(r => {
        return r.data;
      })
      .catch(err => {
        return err;
      });
  }
}
export default new AuthorizationApi();