import axios from 'axios';
import config from '../common/config';

class EmployeeApi {
  addEmployee(employee) {
    const token = localStorage.getItem('jwt')
      ? localStorage.getItem('jwt')
      : sessionStorage.getItem('jwt');

    const configAPi = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    return axios
      .post(
        `${config.trueFingerPrintsAPI.baseURL}/employees`,
        {
          email: employee.email,
          password: employee.password,
          phone: employee.phone,
          fullName: employee.fullName
        },
        configAPi
      )
      .then(r => {
        return r.data;
      })
      .catch(err => {
        return err;
      });
  }
}

export default new EmployeeApi();
