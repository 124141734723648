import React from 'react';
import ReactDOM from 'react-dom';
import './scss/style.scss';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore, { history } from './store';
import NavRouter from './components/Router';
import Footer from './components/Footer/footer';
import { ConnectedRouter } from 'connected-react-router';
import queryString from 'query-string';
import { getUserFromJWT } from './common/utils';

const parsed = queryString.parse(window.location.search , {parseBooleans:true});
if (parsed && parsed.jwt) {
  if (parsed.rememberMe === true) {
    localStorage.setItem('jwt', parsed.jwt);
  } else sessionStorage.setItem('jwt', parsed.jwt);
}

if (!localStorage.getItem('jwt') && !sessionStorage.getItem('jwt')) {
  window.location.href =
    process.env.REACT_APP_STAGE === 'prod'
      ? `${process.env.REACT_APP_TRUE_FINGERPRINTS_WEBSITE_URL}`
      : `${process.env.REACT_APP_TRUE_FINGERPRINTS_WEBSITE_URL}:${process.env.REACT_APP_TRUE_FINGERPRINTS_WEBSITE_PORT}/login`;
}

const user = getUserFromJWT();
if (user) {
  if (parsed.rememberMe === true) {
    localStorage.setItem('userName', user.user.name);
    localStorage.setItem('userRole', user.user.role);
  } else {
    sessionStorage.setItem('userName', user.user.name);
    sessionStorage.setItem('userRole', user.user.role);
  }
}

ReactDOM.render(
  <Provider store={configureStore()}>
    <ConnectedRouter history={history}>
      <NavRouter />
      <Footer />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
