import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { getUserCards } from '../../actions/userActions';
import { connect } from 'react-redux';
import MonthlyIcon from '../../../src/images/MonthlyIcon.png';
import { runMonthlyCheck } from '../../actions/orderActions';
import config from '../../common/config';
import apiCallsHelper from '../../api/apiCallsHelper';
import enums from '../../common/enums';
import ToastService from '../Toasts/toastService';
import ApiHelper from '../../api/apiCallsHelper.js';
import charges from '../../static/charges.json';
import { getAuthorizedAuths, addSubjectAuth } from '../../actions/authorizationActions';
import UserApi from '../../api/user-api';

class MonthlyBackgroundCheck extends Component {
  constructor(props) {
    super(props);
    this.submitOrder = this.submitOrder.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeAuth = this.handleChangeAuth.bind(this);
    this.getServiceIdAndFees = this.getServiceIdAndFees.bind(this);
    this.state = {
      cardToken: '',
      hasOneTimeCheck: true,
      serviceType: process.env.REACT_APP_TRUE_FINGERPRINTS_STATEWIDE_SERVICE_ID,      subjectsWithMonthlyCount: '',
      courtFee: 0,
      authorization: ''
    };
  }
  
  getServiceIdAndFees(state, county) {
    let serviceTypeId = 0,
      courtFee = 0;
    if (state && county) {
      const subjectState = charges[state.split('-').pop()];
      serviceTypeId =
        subjectState['SearchType'] === 'County'
          ? process.env.REACT_APP_TRUE_FINGERPRINTS_COUNTY_SERVICE_ID
          : process.env.REACT_APP_TRUE_FINGERPRINTS_STATEWIDE_SERVICE_ID;

      const surcharge = subjectState['Surcharge'];
      courtFee = 0;
      if (surcharge === 'Yes') {
        const surchargeDetails = subjectState['Surcharge Details'];
        const surchargeDetails2 = subjectState['Surcharge Details 2'];

        if (surchargeDetails['All']) {
          courtFee = surchargeDetails['All'];
        } else if (surchargeDetails[county]) {
          courtFee = surchargeDetails[county];
        } else if (surchargeDetails2 && surchargeDetails2[county]) {
          courtFee = surchargeDetails2[county];
        }
      } else {
        courtFee = 0;
      }
    }
    this.setState({ serviceType: serviceTypeId, courtFee: courtFee });
  }

  async componentDidMount() {
    let user = await UserApi.getUserWithJwt();
    if (user) {
      this.setState({
        user: user
      });
    }

    const req = {
      userId: this.state.user.Id
    }
    this.props.getAuthorizedAuths(req);

    try {
      let orders = await ApiHelper.postWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/individuals/monthly-orders-count`
      );
      this.getServiceIdAndFees(this.props.state, this.props.county);

      this.setState({
        subjectsWithMonthlyCount: orders.data.count
      });

      if (!this.props.cards) {
        this.props.getUserCards();
      }
      // TODO Change to action
      const subjectHasOneTimeCheck = await this.checkIfSubjectHasOneTimeCheck();

      if (!subjectHasOneTimeCheck) {
        this.setState({ hasOneTimeCheck: subjectHasOneTimeCheck });
      }

      this.getServiceIdAndFees(this.props.state, this.props.county);
    } catch (error) { }
  }

  handleChange(e) {
    this.setState({ cardToken: e.target.value });
  }

  handleChangeAuth(e) {
    this.setState({ authorization: e.target.value});
  }

  async checkIfSubjectHasOneTimeCheck() {
    try {
      return await apiCallsHelper.getWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/individuals/has-one-time-check/${this.props.subjectId}`
      );
    } catch (error) {
      // TODO handle error
    }
  }

  async submitOrder() {
    try {
      if (this.state.cardToken === '') {
        this.setState({ error: 'You must select a card' });
        return false;
      }
       
      if (this.props.user.postHireCharge != null) {
        const req = {
          amount: (parseInt(this.props.user.postHireCharge, 10) + this.state.courtFee) * 100,
          subject_id: this.props.subjectId,
          source: this.state.cardToken,
          service_id: this.state.serviceType,
          orderTypeId: enums.OrderTypes.monthly,
          auth: this.state.authorization
        };
        await this.props.runMonthlyCheck(req);
         //store auth for subject
         const data = {
          userId: this.state.user.Id,
          auth: this.state.authorization,
          subject_id: this.props.subjectId,
        }
        this.props.addSubjectAuth(data);
      } else {
        const req = {
          amount: (parseInt(this.props.user.postHireCharge, 10) + this.state.courtFee) * 100,
          subject_id: this.props.subjectId,
          source: this.state.cardToken,
          service_id: this.state.serviceType,
          orderTypeId: enums.OrderTypes.monthly,
          auth: this.state.authorization
        };
        await this.props.runMonthlyCheck(req);
         //store auth for subject
         const data = {
          userId: this.state.user.Id,
          auth: this.state.authorization,
          subject_id: this.props.subjectId,
        }
        this.props.addSubjectAuth(data);
      }

      if (
        this.props.monthlyCheckResult &&
        this.props.monthlyCheckResult.status === 201
      ) {
        ToastService.show(
          'The order has been successfully sent for processing!',
          'success'
        );
      }
      this.props.loadSubjects(this.props.monthlyOrdersCurrentPage, 'test');

      this.props.onHide();
    } catch (err) {
      if (err.response && err.response.status === 422) {
        ToastService.show(
          'PostHire is already in process for this subject',
          'error'
        );
        this.props.onHide();
      } else if (err.response) {
        console.log(err.response);
      } else {
        //handle exception
        console.log('error', err);
        ToastService.show('Something went wrong, please contact customer support.', 'error');
      }
    }
  }

  showToast() {
    ToastService.show(
      'The order has been successfully sent for processing!',
      'success'
    );
  }
  render() {
    let button = null;
    if ((parseInt(this.state.subjectsWithMonthlyCount, 10) < parseInt(this.props.user.postHireLimit, 10)) || ((this.props.user.postHireLimit) === null)) {
      button = <Button onClick={this.submitOrder} className='mt-3 mb-3 btn-secondary'><i className='material-icons'>fingerprint</i>Run PostHire Screening</Button>;
    } else {
      button = <small className='text-danger'>Account Limited - This account does not have an active plan and can not issue PreHire or PostHire checks. For new accounts, you must go through compliance review and then activate a plan. If you previously had an active plan, you've reached your limit. Please contact Personnelgraph support.</small>
    }

    return (
      <Modal
        show={this.props.show}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onHide={this.props.onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            <figure className='icon-group mt-3'>
              <img src={MonthlyIcon} alt='One-Time Check Icon' />
              <h3 className='heading ml-3 mt-3'>
                Initiate PostHire Constant Screening
              </h3>
            </figure>
            {this.state.hasOneTimeCheck ? null : (
              <small className='text-danger'>
                You have not initiated PreHire on this individual yet. It is
                highly recommended to initiate PreHire if this is your first
                contact with this individual.
              </small>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='mb-4 mt-2'>Please select your authorization method and your billing method:</h4>
          <Form.Group controlId='exampleForm.ControlSelect11'>
          <Form.Label>Authorization</Form.Label>
          <Form.Control as='select' onChange={this.handleChangeAuth}>
          <option value=''>Manual</option>
            {this.props.authorizedAuths && this.props.authorizedAuths.length > 0
              ? this.props.authorizedAuths.map(a => {
                return (
                  <option key={a.unique} value={a.unique}>
                    {a.firstName} {a.lastName} - {a.unique}
                  </option>
                );
              })
              :null
            }
            </Form.Control>
            {this.state.authorization === "" ?
               <small className='text-primary w-75'>
                Notice: You have selected “Manual” for your FCRA authorization method. Use this method only if you would like to complete the authorization manually. This process requires you to print the forms from the “Forms” menu and have the individual physically sign them. We highly recommend using the automated FCRA authorization system via the “Authorization” menu above. 
              </small>
              :
              null}
          </Form.Group>
          <Form.Group controlId='exampleForm.ControlSelect1'>
            <Form.Label> Select Your Credit Card</Form.Label>
            <Form.Control as='select' onChange={this.handleChange}>
              <option value=''>Select Card...</option>
              {this.props.cards && this.props.cards.length > 0
                ? this.props.cards.map(r => {
                  return (
                    <option key={r.id} value={r.id}>
                      {r.brand} - {r.last4}
                    </option>
                  );
                })
                : null}
            </Form.Control>
            {this.state.error ? <p className='text-danger'>{this.state.error}</p> : null}
          </Form.Group>
          <Form.Label className='mt-5'>
              {' '}
              Court Fee Surcharge: 
                ${this.state.courtFee}
            </Form.Label>
          <br />
          <Form.Label>
            PostHire Charge:
              ${this.props.user.postHireCharge}
          </Form.Label>
          <br />
          <Form.Label>
              {' '}
              Total Charge:
                ${this.state.courtFee + parseInt(this.props.user.postHireCharge, 10)}
            </Form.Label>
        </Modal.Body>
        <Modal.Footer>
          {button}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapActionsToProps = {
  getUserCards: getUserCards,
  runMonthlyCheck: runMonthlyCheck,
  getAuthorizedAuths: getAuthorizedAuths,
  addSubjectAuth: addSubjectAuth
};

const mapStateToProps = state => {
  return {
    cards: state.userReducer.cards,
    monthlyCheckResult: state.orderReducer.monthlyCheckResult,
    authorizedAuths: state.authorizationReducer.authorizedAuthList
  };
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(MonthlyBackgroundCheck);
