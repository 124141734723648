import {
  ADD_EMPLOYEE_SUCCESS,
  GET_EMPLOYEES_SUCCESS,
  EDIT_EMPLOYEE_SUCCESS
} from '../common/constants';
import employeeApi from '../api/employee-api';
import apiCallsHelper from '../api/apiCallsHelper';
import config from '../common/config';

export function addEmployeeSuccess(employee) {
  return {
    type: ADD_EMPLOYEE_SUCCESS,
    employee
  };
}

export function getEmployeesSuccess(employees) {
  return {
    type: GET_EMPLOYEES_SUCCESS,
    employees
  };
}

function editEmployeeSuccess(employee) {
  return {
    type: EDIT_EMPLOYEE_SUCCESS,
    employee
  };
}

export function addEmployee(employee) {
  return function (dispatch) {
    return employeeApi
      .addEmployee(employee)
      .then(r => {
        if (r.user) {
          dispatch(addEmployeeSuccess(r));
          window.location.href = '/employees';
        } else {
          console.log(r);
        }
      })
      .catch(err => {
        console.log('err', err);
        throw err;
      });
  };
}

export function getEmployees(req) {
  return function (dispatch) {
    return apiCallsHelper
      .postWithJWT(`${config.trueFingerPrintsAPI.baseURL}/employees/all`, req)
      .then(r => {
        dispatch(getEmployeesSuccess(r.data));
      })
      .catch(err => {
        throw err;
      });
  };
}
export function editEmployee(employee) {
  return function (dispatch) {
    return apiCallsHelper
      .postWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/employees/edit`,
        employee
      )
      .then(r => {
        console.log(r);
        if (r.data && r.data[0] === 1) {
          dispatch(editEmployeeSuccess(r));
          window.location.href = '/employees';
        }
      })
      .catch(err => {
        throw err;
      });
  };
}
