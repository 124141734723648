import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import {
  getSubject,
  getSubjectSummary,
  getSubjectOrders,
  deleteSubject
} from '../../actions/subjectActions';
import OneTimeCheckPopUp from '../../components/Modals/OneTimeCheck';
import MonthlyBackgroundCheck from '../../components/Modals/MonthlyBackgroundCheck';
import { Link } from 'react-router-dom';
import apiCallsHelper from '../../api/apiCallsHelper';
import config from '../../common/config';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ToastService from '../Toasts/toastService';
import Loader from '../Spinners/spinnerPage';
import UserApi from './../../api/user-api.js';
import { getSubjectAuths } from '../../actions/authorizationActions';

class ViewSubjectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showMonthlyModal: false,
      additionalInfo: 'info',
      subject_id: '',
      showLoader: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.saveNote = this.saveNote.bind(this);
    this.submitDelete = this.submitDelete.bind(this);
  }

  async componentDidMount() {
    try {
      this.props.getSubject(this.props.location.state.subjectId);
      this.props.getSubjectSummary(this.props.location.state.subjectId);
      this.props.getSubjectOrders(this.props.location.state.subjectId);
      this.props.getSubjectAuths(this.props.location.state.subjectId);
      this.setState({ subject_id: this.props.location.state.subjectId });

      let user = await UserApi.getUserWithJwt();

      if (user) {
        this.setState({
          user: user
        });
      }

      let orders = await apiCallsHelper.postWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/orders/orders-limit`
      );

      this.setState({
        orders: orders.data.count
      });
    }
    catch (error) { }
  }

  componentWillReceiveProps() {
    this.setState({
      additionalInfo: this.props.subject
        ? this.props.subject.additionalInfo
        : ''
    });
  }
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleDeleteSubject() {
    this.props.deleteSubject(this.props.location.state.subjectId);
  }

  async saveNote() {
    this.setState({ showLoader: true });
    try {
      const req = {
        subject_id: this.state.subject_id,
        additionalInfo: this.state.additionalInfo
      };
      await apiCallsHelper.postWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/individuals/additional-info`,
        req
      );
      this.setState({ showLoader: false });
      ToastService.show('Successfully added additional info!', 'success');
    } catch (error) {
      // handle error
      console.log('error', error.response);
      this.setState({ showLoader: false });
      ToastService.show(
        `${error.response.statusText},please contact customer support.`,
        'error'
      );
    }
  }

  showToast() {
    ToastService.show('Successfully added new card!', 'success');
  }

  submitDelete = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Remove Individual?</h1>
            <p>Are you sure you want to remove this individual?</p>
            <Button variant='outline-secondary mt-3' onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant='danger ml-4 mt-3'
              onClick={() => {
                this.handleDeleteSubject();
                onClose();
              }}
            >
              Yes, Remove it!
            </Button>
          </div>
        );
      }
    });
  };

  render() {
    const loader = this.state.showLoader ? <Loader /> : null;
    return (
      <Container>
        <Row>
          <Col lg={12}>
            {this.props.subjectOrders && this.props.subjectOrders.length > 0 ? (
              <p className='subject-cannot-edit-info'>
                {' '}
                This individual can not be edited while a search has been
                initiated. If you would like to make a new search with new
                information for the same individual please add the same
                individual again in the Individuals page.
              </p>
            ) : (
                <ButtonToolbar className='d-flex justify-content-center mt-5 mb-5'>
                  <Link
                    variant='outline-primary'
                    to={{
                      pathname: '/edit-subject',
                      state: {
                        currentSubjectId: this.props.location.state.subjectId
                      }
                    }}
                  >
                    <i className='material-icons'>edit</i>
                  Edit Individual's Info
                </Link>
                </ButtonToolbar>
              )}
            <hr />
            <h1 className='text-center mt-5'>
              {this.props.subject ? this.props.subject.name_first : null}{' '}
              {this.props.subject && this.props.subject.name_middle
                ? this.props.subject.name_middle
                : null}{' '}
              {this.props.subject ? this.props.subject.name_last : null}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className='mt-3 mb-3' lg={12}>
            <ButtonToolbar className='d-flex justify-content-center mb-5'>
              {this.props.subject && this.props.subject['Orders.id'] ? (
                <Button
                  variant='primary mx-3 mt-3'
                  href='#'
                  className='disabled'
                >
                  <i className='material-icons'>fingerprint</i>PreHire
                  Processing
                </Button>
              ) : (
                  <Button
                    variant='primary mx-3 mt-3'
                    href='#'
                    onClick={() => this.setState({ showModal: true })}
                  >
                    <i className='material-icons'>fingerprint</i>Run PreHire
                  Screening
                  </Button>
                )}
              {this.props.subject && this.props.subject['MonthlyOrder.id'] ? (
                <Button
                  variant='secondary mx-3 mt-3'
                  href='#'
                  className='disabled'
                >
                  <i className='material-icons'>fingerprint</i>PostHire
                  Processing
                </Button>
              ) : (
                  <Button
                    variant='secondary mx-3 mt-3'
                    href='#'
                    onClick={() => this.setState({ showMonthlyModal: true })}
                  >
                    <i className='material-icons'>fingerprint</i>Run PostHire
                  Screening
                  </Button>
                )}
            </ButtonToolbar>
          </Col>
          <Col lg={6}>
            <article className='results-component subject-info mb-5'>
              <ListGroup variant='flush'>
                <h5 className='mb-4 mt-3'>Individual's Information</h5>
                <ListGroup.Item>
                  First Name:
                  <span className='pl-2'>
                    {this.props.subject ? this.props.subject.name_first : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Middle Name:
                  <span className='pl-2'>
                    {this.props.subject && this.props.subject.name_middle
                      ? this.props.subject.name_middle
                      : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Last Name:
                  <span className='pl-2'>
                    {this.props.subject ? this.props.subject.name_last : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  City:
                  <span className='pl-2'>
                    {this.props.subject ? this.props.subject.city : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  State:
                  <span className='pl-2'>
                    {this.props.subject
                      ? this.props.subject.state.split('-').pop()
                      : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  County:
                  <span className='pl-2'>
                    {this.props.subject ? this.props.subject.county : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Zip:
                  <span className='pl-2'>
                    {this.props.subject ? this.props.subject.zip : null}
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </article>
          </Col>
          <Col lg={6}>
            <article className='results-component subject-info mb-5'>
              <ListGroup variant='flush'>
                <h5 className='mb-4 mt-3'>Additional Information</h5>
                <ListGroup.Item>
                  DOB:
                  <span className='pl-2'>
                    {this.props.subject && this.props.subject.json_result
                      ? JSON.parse(this.props.subject.json_result).dob
                      : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  SSN:
                  <span className='pl-2'>
                    {this.props.subject ? this.props.subject.ssn : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Address 1:
                  <span className='pl-2'>
                    {this.props.subject ? this.props.subject.address1 : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Address 2:
                  <span className='pl-2'>
                    {this.props.subject ? this.props.subject.address2 : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  Gender:
                  <span className='pl-2'>
                    {this.props.subject
                      ? JSON.parse(this.props.subject.json_result).gender
                      : null}
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  phone:
                  <span className='pl-2'>
                    {this.props.subject ? this.props.subject.phone : null}
                  </span>
                </ListGroup.Item>
              </ListGroup>
            </article>
          </Col>

          <Col lg={12}>
            <article className='results-component subject-info mb-5'>
              <ListGroup variant='flush'>
              <h5 className='mb-4 mt-3'>FCRA Authorization</h5>
              <ListGroup.Item>
                Authorization Method:
              <span className='pl-2'>
              {this.props.subjectAuth && this.props.subjectAuth.length ?
                  this.props.subjectAuth.map(s => {
                    return (
                      <span key={s.id}>
                        {"Automatic: " + s.firstName + " " + s.lastName + " [AUTHORIZED]" +"; "}
                      </span>
                    )
                  }) 
                : "Manual"}
              </span>
              </ListGroup.Item>
              </ListGroup>
            </article>
          </Col>

          <Col lg={12}>
            <article className='results-component subject-summary mb-5'>
              <h5 className='mt-3'>Background Checks Summary</h5>

              <Table responsive>
                <thead>
                  <tr>
                    <th>Total Orders</th>
                    <th>Cases</th>
                    <th>Charges</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {this.props.subjectSummary
                        ? this.props.subjectSummary.ordersCount
                        : 0}
                    </td>
                    <td>
                      {this.props.subjectSummary
                        ? this.props.subjectSummary.casesCount
                        : 0}
                    </td>
                    <td>
                      {this.props.subjectSummary
                        ? this.props.subjectSummary.chargesCount
                        : 0}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </article>
          </Col>

          <Col lg={12}>
            <h5 className='box title-box-quaternary mt-5'>
              List of All Background Checks
            </h5>
          </Col>

          <Col lg={12}>
            <Table responsive>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Order Number</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.subjectOrders
                  ? this.props.subjectOrders.map(i => {
                    return (
                      <tr key={i.id}>
                        <td>{i.createdAt}</td>
                        <td>{i.order_id}</td>
                        <td>
                          <Link
                            to={{
                              pathname: '/history',
                              state: {
                                currentOrderId: i.id,
                                currentSubjectId: i.subject_id
                              }
                            }}
                          >
                            <Button variant='primary' href='/history'>
                              <i className='material-icons'>pageview</i>
                                View Results
                              </Button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                  : null}
              </tbody>
            </Table>
          </Col>

          <Col lg={12}>
            <h5 className='box title-box-quaternary mt-5 mb-3'>
              Additional Information
            </h5>
          </Col>

          <Col lg={12}>
            <Form.Group controlId='additionalInfoArea'>
              <Form.Label className='mb-3'>
                You can write additional information about this Individual here.
                Your Account Manager may enter additional information about this
                Individual in the field below.
              </Form.Label>
              <Form.Control
                as='textarea'
                rows='5'
                onChange={this.handleChange}
                name='additionalInfo'
                value={this.state.additionalInfo}
              />
            </Form.Group>
            {loader}
            <ButtonToolbar className='d-flex justify-content-end mt-3'>
              <Button variant='primary' onClick={this.saveNote}>
                <i className='material-icons'>save</i>
                Save
              </Button>
            </ButtonToolbar>
          </Col>

          <Col className='mt-5' lg={12}>
            <hr />
            <ButtonToolbar className='d-flex justify-content-center mt-5'>
              <Button variant='outline-danger' onClick={this.submitDelete}>
                <i className='material-icons'>delete_forever</i>
                Remove This Individual
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
        {this.state.showModal ? (
          <OneTimeCheckPopUp
            show={this.state.showModal}
            onHide={() => this.setState({ showModal: false })}
            subjectId={this.props.location.state.subjectId}
            county={this.props.subject ? this.props.subject.county : null}
            state={this.props.subject ? this.props.subject.state : null}
            user={this.state.user}
          />
        ) : null}
        {this.state.showMonthlyModal ? (
          <MonthlyBackgroundCheck
            show={this.state.showMonthlyModal}
            onHide={() => this.setState({ showMonthlyModal: false })}
            subjectId={this.props.location.state.subjectId}
            county={this.props.subject ? this.props.subject.county : null}
            state={this.props.subject ? this.props.subject.state : null}
            user={this.state.user}
          />
        ) : null}
      </Container>
    );
  }
}

const mapActionsToProps = {
  getSubject,
  getSubjectSummary,
  getSubjectOrders,
  deleteSubject,
  getSubjectAuths
};
const mapStateToProps = state => {
  return {
    subject: state.subjectReducer.subject,
    subjectSummary: state.subjectReducer.subjectSummary,
    subjectOrders: state.subjectReducer.subjectOrders,
    subjectAuth: state.authorizationReducer.subjectAuth
    
  };
};

export default connect(mapStateToProps, mapActionsToProps)(ViewSubjectPage);
