import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { getOrdersByResult } from '../../actions/orderActions';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import enums from '../../common/enums';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

class ResultsAwaitingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pendingSearchText: '',
      currentPage: 1
    };
    this.loadOrders = this.loadOrders.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
  }

  handlePageClick = e => {
    this.loadOrders(e.selected + 1);
  };
  componentDidMount() {
    this.loadOrders(1);
  }

  handleTextChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  loadOrders(page) {
    this.props.getOrdersByResult(
      enums.OrderResult.Pending,
      this.state.pendingSearchText,
      page
    );
  }
  render() {
    return (
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to='/'>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Awaiting</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className='table-section'>
          <Col className='mt-5' lg={12}>
            <h2 className='heading mt-5'>
              <i className='material-icons md-36 awaiting-colorize'>
                hourglass_full
              </i>
              Awaiting Individuals List
            </h2>
            <Form inline>
              <FormControl
                type='text'
                placeholder='Search Individuals'
                className='mr-sm-2'
                onChange={this.handleTextChange}
                name='pendingSearchText'
              />
              <Button
                variant='outline-dark mb-3 mt-3'
                onClick={() => this.loadOrders(1)}
              >
                Find
              </Button>
            </Form>
            <section className='list-box mt-3'>
              <Table responsive='xl' className='mb-5'>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Full Name</th>
                    <th>Location</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.ordersByResult
                    ? this.props.ordersByResult.rows.map(i => {
                        return (
                          <tr key={i.id}>
                            <td>{dateFormat(i.date, 'mmm dd yyyy')}</td>
                            <td>{`${i['Subject.name_first']} ${
                              i['Subject.name_middle'] ? i['Subject.name_middle'] : ''
                            } ${i['Subject.name_last']}`}</td>
                            <td>{`${i['Subject.city']} ${i['Subject.state'] ? i['Subject.state'].split('-').pop() : null} ${
                              i['Subject.zip']
                            }`}</td>
                            <td>{i.status.replace(/^\w/, c => c.toUpperCase())}</td>
                            <td>
                              <Button variant='primary' href='#' disabled>
                                <i className='material-icons'>pageview</i>
                                Pending
                              </Button>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={'< Previous'}
                nextLabel={'Next >'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={
                  this.props.ordersByResult
                    ? Math.ceil(this.props.ordersByResult.count / 10)
                    : 1
                }
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={'list-group-pagination pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                disabledClassName={'page-item disabled'}
                activeClassName={'page-item active'}
              />
            </section>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapActionsToProps = {
  getOrdersByResult
};

const mapStateToProps = state => {
  return {
    ordersByResult: state.orderReducer.ordersByResult
  };
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ResultsAwaitingPage);
