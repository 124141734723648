import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function carValidation(data) {
  let errors = {};

  if (Validator.isEmpty(data.billingAddress)) {
    errors.billingAddress = 'Billing address is required';
  }

  if (Validator.isEmpty(data.billingZipCode)) {
    errors.billingZipCode = 'Zip code is required';
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
}
