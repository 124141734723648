import React, { Component } from 'react';
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import App from '../App';
import UserProfilePage from '../components/User/userProfilePage';
import FormsPage from '../components/Forms/formsPage';
import SettingsPage from '../components/Settings/settingsPage';
import PaymentHistoryPage from './Payments/payments';
import SubjectsListPage from '../components/Subjects/list';
import AddSubjectPage from '../components/Subjects/addSubject';
import ViewSubjectPage from '../components/Subjects/viewSubject';
import EditSubjectPage from '../components/Subjects/editSubject';
import SubjectsHistoryPage from '../components/Subjects/history';
import SubjectsListForAdmin from '../components/Subjects/listForAdmin';
import SubjectsHistoryListPage from '../components/Subjects/historyList';
import ResultsClearPage from '../components/Results/clear';
import ResultsAlertsPage from '../components/Results/alerts';
import ResultsAwaitingPage from '../components/Results/awaiting';
import NotificationsPage from '../components/Notifications/notificationsPage';
import EditEmployeePage from '../components/Admin/Employees/editEmployee';
import ErrorPage from '../components/Error/errorPage';
import Logout from '../components/User/logout';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../images/PersonnelgraphLogoLight.svg';
import Badge from 'react-bootstrap/Badge';
import ApiHelper from '../api/apiCallsHelper.js';
import PubSub from 'pubsub-js';
import AddEmployeePage from '../components/Admin/Employees/addEmployee';
import EmployeesListPage from '../components/Admin/Employees/listEmployees';
import SubjectsMonthlyListPage from '../components/Admin/subjectsMonthlyList';
import ToastPage from '../components/Toasts/toastPage';
import config from '../common/config';
import ProcessingOrdersPage from '../components/Order/ProcessingOrdersList';
import UsersListPage from '../components/Admin/Users/listUsers';
import EditUserPage from '../components/Admin/Users/editUser';
import FcraPage from '../components/FCRA/fcraPage';

class NavRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationCount: 0,
      role: ''
    };
  }
  isLoggedIn() {
    if (localStorage.getItem('jwt') || sessionStorage.getItem('jwt')) {
      return true;
    }
    return false;
  }

  async componentWillMount() {
    try {
      let count = await ApiHelper.getWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/notifications/seen`
      );

      this.setState({
        notificationCount: count.count,
        role:
          localStorage.getItem('userRole') != null
            ? localStorage.getItem('userRole')
            : sessionStorage.getItem('userRole')
      });

      let self = this;
      PubSub.subscribe('change_notification', function (msg, data) {
        self.setState({
          notificationCount:
            self.state.notificationCount === 0
              ? self.state.notificationCount
              : self.state.notificationCount - 1
        });
      });
    }
    catch (error) { }
  }

  render() {
    let employeesLink = null;
    let usersLink = null;
    let subjects = (
      <Route
        path='/individuals'
        component={this.isLoggedIn() ? SubjectsListPage : Logout}
      />
    );

    if (this.state.role === 'sysadmin') {
      employeesLink = (
        <Link to='/employees' className='nav-link'>
          Employees
        </Link>
      );
      usersLink = (
        <Link to='/users' className='nav-link'>
          Users
        </Link>
      );
      subjects = (
        <Route
          path='/individuals'
          component={this.isLoggedIn() ? SubjectsListForAdmin : Logout}
        />
      );
    }

    if (this.state.role === 'employee') {
      subjects = (
        <Route
          path='/individuals'
          component={this.isLoggedIn() ? SubjectsListForAdmin : Logout}
        />
      );
    }
    return (
      <Router>
        <Navbar
          sticky='top'
          expand='lg'
          variant='dark'
          className='main-navigation'
        >
          <Navbar.Brand>
            <Link to='/' className='navbar-brand'>
              <img src={Logo} width='250' alt='Personnelgraph-Logo' />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='mr-auto'>
              {this.state.role === 'user' ? (
                <Link to='/' className='nav-link'>
                  Overview
                </Link>
              ) : (
                  <p>{this.state.role}</p>
                )}
              <Link to='/individuals' className='nav-link'>
                Individuals
              </Link>
              <Link to='/forms' className='nav-link'>
                Forms
              </Link>
              <Link to='/authorization' className='nav-link'>
                Authorization
              </Link>
              {employeesLink}
              {usersLink}
            </Nav>
            <Nav className='ml-auto'>
              <Link to='/notifications' className='nav-link'>
                <i className='material-icons'>notifications</i>
                <Badge pill variant='primary'>
                  {this.state.notificationCount}
                </Badge>
              </Link>
              <NavDropdown
                title={
                  localStorage.getItem('userName') ||
                  sessionStorage.getItem('userName')
                }
                id='basic-nav-dropdown'
              >
                <Link to='/processing-orders' className='dropdown-item'>
                  <i className='material-icons'>view_list</i>Orders in Process
                </Link>
                <NavDropdown.Divider />
                <Link to='/payments' className='dropdown-item'>
                  <i className='material-icons'>monetization_on</i>Payments
                </Link>
                <Link to='/settings' className='dropdown-item'>
                  <i className='material-icons'>settings</i>Settings
                </Link>
                <NavDropdown.Divider />
                <Link to='/log-out' className='dropdown-item'>
                  <i className='material-icons'>exit_to_app</i>Log Out
                </Link>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Switch>
          UserProfilePage
          {this.state.role === 'user' ? (
            <Route exact path='/' component={App} />
          ) : (
              <Route exact path='/' component={SubjectsListForAdmin} />
            )}
          <Route
            path='/user-profile'
            component={this.isLoggedIn() ? UserProfilePage : Logout}
          />
          <Route
            path='/forms'
            component={this.isLoggedIn() ? FormsPage : Logout}
          />
          <Route
            path='/authorization'
            component={this.isLoggedIn() ? FcraPage : Logout}
          />
          <Route
            path='/settings'
            component={this.isLoggedIn() ? SettingsPage : Logout}
          />
          <Route
            path='/payments'
            component={this.isLoggedIn() ? PaymentHistoryPage : Logout}
          />
          {subjects}
          <Route
            path='/add-subject'
            component={this.isLoggedIn() ? AddSubjectPage : Logout}
          />
          <Route
            path='/view-subject'
            component={this.isLoggedIn() ? ViewSubjectPage : Logout}
          />
          <Route
            path='/edit-subject'
            component={this.isLoggedIn() ? EditSubjectPage : Logout}
          />
          <Route
            path='/history'
            component={this.isLoggedIn() ? SubjectsHistoryPage : Logout}
          />
          <Route
            path='/history-list'
            component={this.isLoggedIn() ? SubjectsHistoryListPage : Logout}
          />
          <Route
            path='/results/clear'
            component={this.isLoggedIn() ? ResultsClearPage : Logout}
          />
          <Route
            path='/results/alerts'
            component={this.isLoggedIn() ? ResultsAlertsPage : Logout}
          />
          <Route
            path='/results/awaiting'
            component={this.isLoggedIn() ? ResultsAwaitingPage : Logout}
          />
          <Route
            path='/notifications'
            component={this.isLoggedIn() ? NotificationsPage : Logout}
          />
          <Route
            path='/employees'
            component={this.isLoggedIn() ? EmployeesListPage : Logout}
          />
          <Route
            path='/add-employee'
            component={this.isLoggedIn() ? AddEmployeePage : Logout}
          />
          <Route
            path='/admin-subjects-monthly'
            component={this.isLoggedIn() ? SubjectsMonthlyListPage : Logout}
          />
          <Route
            path='/error'
            component={this.isLoggedIn() ? ErrorPage : Logout}
          />
          <Route
            path='/editEmployee'
            component={this.isLoggedIn() ? EditEmployeePage : Logout}
          />
          <Route
            path='/processing-orders'
            component={this.isLoggedIn() ? ProcessingOrdersPage : Logout}
          />
          <Route
            path='/users'
            component={this.isLoggedIn() ? UsersListPage : Logout}
          />
          <Route
            path='/editUser'
            component={this.isLoggedIn() ? EditUserPage : Logout}
          />
          <Route path='/log-out' component={Logout} />
          <Route path='*' component={ErrorPage} />
        </Switch>
        <ToastPage />
      </Router>
    );
  }
}
export default NavRouter;
