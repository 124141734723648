import React  from 'react';
class ActionHelpers {
  renderOrderStatus(orderResultStatus) {
    switch (orderResultStatus) {
      case 'Awaiting':
        return (
            <div> <i className='material-icons awaiting-colorize'>hourglass_full</i> Awaiting</div>         
        );
      case 'Clear':
        return (
          <div><i className='material-icons clear-colorize'>check_circle</i> Clear</div>
        );
      case 'Alert':
        return (
          <div><i className='material-icons alert-colorize'>warning</i> Alert</div>
        );
      default:
        return <div></div>;
    }
  }
}

export default new ActionHelpers();
