import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MonthlyIcon from '../../../src/images/MonthlyIcon.png';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { connect } from 'react-redux';
import {
  getMonthlyCheckList,
  setCurrentOrderId
} from '../../actions/orderActions';
import { setCurrentSubjectId } from '../../actions/subjectActions';
import enums from '../../common/enums';
import dateFormat from 'dateformat';
import { Redirect } from 'react-router-dom';
import apiCallsHelper from '../../api/apiCallsHelper';
import config from '../../common/config';
import { confirmAlert } from 'react-confirm-alert';
import ToastService from '../Toasts/toastService';
import { Link } from 'react-router-dom';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

class MonthlyOrdersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlySearchText: '',
      redirect: false,
      currentPage: 1
    };
    this.handleTextChange = this.handleTextChange.bind(this);
    this.findOrders = this.findOrders.bind(this);
    this.setOrderAndSubject = this.setOrderAndSubject.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  findOrders() {
    this.loadOrders(1);
  }

  handlePageClick = e => {
    this.setState({ currentPage: this.state.currentPage + 1 });
    this.loadOrders(e.selected + 1);
  };

  componentDidMount() {
    this.loadOrders(1);
  }

  handleTextChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  loadOrders(page) {
    const req = {
      page: page,
      orderType: enums.OrderTypes.monthly,
      searchText: this.state.monthlySearchText
    };
    this.props.getMonthlyCheckList(req);
  }

  handleSubmit(e) {
    e.preventDefault();
  }
  setOrderAndSubject(order_id, subject_id) {
    this.props.setCurrentOrderId(order_id);
    this.props.setCurrentSubjectId(subject_id);
    this.setState({
      redirect: true
    });
  }

  async handleClickDelete(id) {
    try {
      const result = await apiCallsHelper.postWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/orders/unsubscribe-monthly/${id}`,
        id
      );
      if (result.status === 200) {
        this.loadOrders(this.state.currentPage);
        ToastService.show('Successfully unsubscribed', 'success');
      } else {
        ToastService.show('Subscription has not be canceled', 'error');
      }
    } catch (error) {
      ToastService.show('Subscription has not be canceled', 'error');
      // Throw error
    }
  }
  unsubscribe = id => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Unsubscribe</h1>
            <p>Are you sure you want to unsubscribe from this subscription? </p>
            <Button variant='outline-secondary mt-3' onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant='danger ml-4 mt-3'
              onClick={() => {
                this.handleClickDelete(id);
                onClose();
              }}
            >
              Yes, unsubscribe!
            </Button>
          </div>
        );
      }
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to='/history' />;
    }
    return (
      <Row className='table-section mt-5'>
        <Col lg={12}>
          <figure className='icon-group mt-5'>
            <img src={MonthlyIcon} alt='Monthly Check Icon' />
            <h2 className='heading ml-3'>PostHire List</h2>
          </figure>

          <Form inline onSubmit={this.handleSubmit}>
            <FormControl
              type='text'
              placeholder='Search Individuals'
              className='mr-sm-2'
              onChange={this.handleTextChange}
              name='monthlySearchText'
            />
            <Button variant='outline-dark mb-3 mt-3' onClick={this.findOrders}>
              Find
            </Button>
          </Form>
          <section className='list-box mt-3'>
            <Table responsive='xl' className='mb-5'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Full Name</th>
                  <th>Location</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.monthlyOrdersList
                  ? this.props.monthlyOrdersList.rows.map(i => {
                      return (
                        <tr key={i.Subject.subject_id}>
                          <td>{dateFormat(i.createdAt, 'mmm dd yyyy')}</td>
                          <td>{`${i.Subject.name_first} ${
                            i.Subject.name_middle ? i.Subject.name_middle : ''
                          } ${i.Subject.name_last}`}</td>
                          <td>{`${i.Subject.city} ${i.Subject.state.split('-').pop()} ${i.Subject.zip}`}</td>
                          <td>
                          Currently Monitoring
                          </td>
                          <td>
                            <ButtonToolbar>
                              <Link
                                to={{
                                  pathname: '/view-subject',
                                  state: { subjectId: i.Subject.subject_id }
                                }}
                                className='mr-3'
                              >
                                <Button variant='primary'>
                                  <i className='material-icons'>visibility</i>
                                  View Profile
                                </Button>
                              </Link>
                              <Button
                                variant='outline-secondary'
                                onClick={() =>
                                  this.unsubscribe(i.Subject.subject_id)
                                }
                              >
                                <i className='material-icons'>replay</i>
                                Unsubscribe
                              </Button>
                            </ButtonToolbar>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </Table>
            <ReactPaginate
              previousLabel={'< Previous'}
              nextLabel={'Next >'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={
                this.props.monthlyOrdersList &&
                this.props.monthlyOrdersList.orders
                  ? Math.ceil(this.props.monthlyOrdersList.ordersCount / 10)
                  : 1
              }
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'list-group-pagination pagination'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousClassName={'page-item'}
              previousLinkClassName={'page-link'}
              nextClassName={'page-item'}
              nextLinkClassName={'page-link'}
              disabledClassName={'page-item disabled'}
              activeClassName={'page-item active'}
            />
          </section>
        </Col>
      </Row>
    );
  }
}

const mapActionsToProps = {
  getMonthlyCheckList: getMonthlyCheckList,
  setCurrentOrderId: setCurrentOrderId,
  setCurrentSubjectId: setCurrentSubjectId
};

const mapStateToProps = state => {
  return {
    monthlyOrdersList: state.orderReducer.monthlyOrdersList
  };
};

export default connect(mapStateToProps, mapActionsToProps)(MonthlyOrdersList);
