import React from 'react';
import classnames from 'classnames';

const TextFieldGroup = ({
  field,
  value,
  label,
  error,
  type,
  onChange,
  checkUserExists,
  required
}) => {
  return (
    <div className={classnames('form-group', { 'has-error': error })}>
      <label className="control-label">{label} {required ? <span className="text-danger"> *</span> : null}</label>
      <input
        onChange={onChange}
        onBlur={checkUserExists}
        value={value}
        type={type}
        name={field}
        className="form-control"
      />
      {error && <span className="help-block text-danger">{error}</span>}
    </div>
  );
};

export default TextFieldGroup;
