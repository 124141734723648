import {
  ADD_EMPLOYEE_SUCCESS,
  GET_EMPLOYEES_SUCCESS,
  EDIT_EMPLOYEE_SUCCESS
} from '../common/constants';

export default (state = [], action) => {
  switch (action.type) {
    case ADD_EMPLOYEE_SUCCESS:
      return {
        result: action.employee
      };
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employeesList: action.employees
      };
    case EDIT_EMPLOYEE_SUCCESS:
      return {
        ...state,
        result: action.employee
      };
    default:
      return state;
  }
};
