import axios from 'axios';
import config from '../common/config';

class UserApi {
  getUser(userId) {
    const token = localStorage.getItem('jwt')
      ? localStorage.getItem('jwt')
      : sessionStorage.getItem('jwt');

    const configAPi = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    return axios
      .get(
        process.env.NODE_ENV === 'production'
          ? `${process.env.REACT_APP_TRUE_FINGERPRINTS_API_BASE_URL}/api/v1/users/${userId}`
          : `${process.env.REACT_APP_TRUE_FINGERPRINTS_API_BASE_URL}:${process.env.REACT_APP_TRUE_FINGERPRINTS_API_PORT}/api/v1/users/${userId}`, configAPi
      )
      .then(r => {
        return r.data.user;
      })
      .catch(err => {
        return err;
      });
  }

  getUserWithJwt() {
    const token = localStorage.getItem('jwt')
      ? localStorage.getItem('jwt')
      : sessionStorage.getItem('jwt');

    const configAPi = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    return axios
      .get(`${config.trueFingerPrintsAPI.baseURL}/users/get-user`, configAPi)
      .then(r => {
        return r.data.user;
      })
      .catch(err => {
        return err;
      });
  }

  updateUser(user) {
    const token = localStorage.getItem('jwt')
      ? localStorage.getItem('jwt')
      : sessionStorage.getItem('jwt');

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    return axios
      .put(
        process.env.NODE_ENV === 'production'
          ? `${process.env.REACT_APP_TRUE_FINGERPRINTS_API_BASE_URL}/api/v1/users/update`
          : `${process.env.REACT_APP_TRUE_FINGERPRINTS_API_BASE_URL}:${process.env.REACT_APP_TRUE_FINGERPRINTS_API_PORT}/api/v1/users/update`,
        {
          Id: user.id,
          fullName: user.fullName,
          email: user.email,
          password: user.password,
          phone: user.phone,
          creditCard: user.creditCard
        },
        config
      )
      .then(r => {
        return r.data;
      })
      .catch(err => {
        return err;
      });
  }

  emailExist(email) {
    return axios
      .get(
        process.env.NODE_ENV === 'production'
          ? `${process.env.REACT_APP_TRUE_FINGERPRINTS_API_BASE_URL}/api/v1/users/email-exist/${email}`
          : `${process.env.REACT_APP_TRUE_FINGERPRINTS_API_BASE_URL}:${process.env.REACT_APP_TRUE_FINGERPRINTS_API_PORT}/api/v1/users/email-exist/${email}`
      )
      .then(r => {
        return r.data;
      })
      .catch(err => {
        return err;
      });
  }

  updateUserByAdmin(user) {
    const token = localStorage.getItem('jwt')
      ? localStorage.getItem('jwt')
      : sessionStorage.getItem('jwt');

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    return axios
      .post(
        process.env.NODE_ENV === 'production'
          ? `${process.env.REACT_APP_TRUE_FINGERPRINTS_API_BASE_URL}/api/v1/users/edit-user`
          : `${process.env.REACT_APP_TRUE_FINGERPRINTS_API_BASE_URL}:${process.env.REACT_APP_TRUE_FINGERPRINTS_API_PORT}/api/v1/users/edit-user`,
        {
          Id: user.id,
          fullName: user.fullName,
          email: user.email,
          phone: user.phone,
          preHireCharge: user.preHireCharge,
          postHireCharge: user.postHireCharge,
          preHireLimit: user.preHireLimit,
          postHireLimit: user.postHireLimit
        },
        config
      )
      .then(r => {
        return r.data;
      })
      .catch(err => {
        return err;
      });
  }
}

export default new UserApi();
