import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import UserApi from '../api/user-api';

export default async function validateEditUser(data, email) {
  let errors = {};
  if (Validator.isEmpty(data.fullName)) {
    errors.fullName = 'Full name is required';
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = 'Email is required';
  }
  if (!email === data.email) {
    if (!Validator.isEmail(data.email)) {
      errors.email = 'Email is invalid';
    }

    if (data.email) {
      const emailAlreadyExist = await UserApi.emailExist(data.email);
      if (emailAlreadyExist.emailExist) {
        errors.email = 'Email already exists';
      }
    }
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
}
