import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ErrorImg from '../../images//ErrorImg.png';
import { Link } from 'react-router-dom';

class ErrorPage extends Component {
  render() {
    return (
      <Container fluid>
        <Row>
          <Col lg={12} className="text-group mt-5 px-5 text-center">
            <h1>Sorry, we can't find that page</h1>
            <h5 className="heading  my-3 px-5">
              It might be an old link or maybe it moved.
            </h5>
            <Link to='/'>
            <Button variant="primary" className="mt-3 mb-5 text-center">
              <i class="material-icons">home</i>Take Me Back Home
            </Button>
            </Link>
          </Col>
         
          <Col lg={12} className="mt-5 px-0 mb-5 text-center">
            <figure className="error-img">
              <img
                src={ErrorImg}
                className="img-fluid"
                alt="404 Page not found"
              />
            </figure>
            
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ErrorPage;
