import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { getUserProcessingOrders } from '../../actions/orderActions';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';
import Button from 'react-bootstrap/Button';
import apiCallsHelper from '../../api/apiCallsHelper';
import config from '../../common/config';
import { confirmAlert } from 'react-confirm-alert';
import ToastService from '../Toasts/toastService';

class ProcessingOrdersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchText: ''
    };
    this.loadProcessingOrders = this.loadProcessingOrders.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  componentDidMount() {
    this.loadProcessingOrders(1);
  }

  loadProcessingOrders(page) {
    this.props.getUserProcessingOrders(page);
  }

  handlePageClick = e => {
    this.setState({ currentPage: e + 1 });
    this.loadProcessingOrders(e.selected + 1);
  };

  async handleClickDelete(id) {
    try {
      const result = await apiCallsHelper.postWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/orders/cancel-order/${id}`,
        id
      );
      if (result.status === 200) {
        this.loadProcessingOrders(this.state.currentPage);
        ToastService.show('Order has been canceled', 'success');
      } else {
        ToastService.show('Order has not been canceled', 'error');
      }
    } catch (error) {
      // Throw error
    }
  }
  delete = id => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Cancel Order?</h1>
            <p>Are you sure you want to cancel this order? </p>
            <Button variant='outline-secondary mt-3' onClick={onClose}>
              No
            </Button>
            <Button
              variant='danger ml-4 mt-3'
              onClick={() => {
                this.handleClickDelete(id);
                onClose();
              }}
            >
              Cancel Order
            </Button>
          </div>
        );
      }
    });
  };
  render() {
    return (
      <Container>
        <Row>
          <Col lg={12}></Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Card bg='secondary' className='card-box-dark mt-4'>
              <Card.Title className='text-white mb-4'>
                <h2>
                  <i className='material-icons md-36 mt-4 ml-3'>view</i>
                  Orders in process
                </h2>
              </Card.Title>

              <Table striped bordered hover variant='dark' responsive='xl'>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Payment description</th>
                    <th>Total Amount ($)</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.processingOrders
                    ? this.props.processingOrders.rows.map(i => {
                      return (
                        <tr key={i.id}>
                          <td>{dateFormat(i.createdAt, 'mmm dd yyyy')}</td>
                          <td>{JSON.parse(i.json).chargeDescription}</td>
                          <td>{JSON.parse(i.json).amount / 100}</td>
                          <td>
                            <Button
                              variant='danger'
                              onClick={() => this.delete(i.id)}
                            >
                              Cancel
                              </Button>
                          </td>
                        </tr>
                      );
                    })
                    : null}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={'< Previous'}
                nextLabel={'Next >'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={
                  this.props.processingOrders &&
                    this.props.processingOrders.count
                    ? Math.ceil(this.props.processingOrders.count / 10)
                    : 1
                }
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={'list-group-pagination pagination pb-0'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                disabledClassName={'page-item disabled'}
                activeClassName={'page-item active'}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapActionsToProps = {
  getUserProcessingOrders
};

const mapStateToProps = state => {
  return {
    processingOrders: state.orderReducer.processingOrders
  };
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(ProcessingOrdersPage);
