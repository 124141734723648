import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import TextFieldGroup from '../common/textFieldGroup';
import SelectFieldGroup from '../common/selectFieldGroup';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { editSubject } from '../../actions/subjectActions';
import validateAddSubject from '../../validations/addSubjectValidation';
import { withRouter } from 'react-router';
import States from '../../static/states.json';
import SelectFieldGroupFromArray from '../common/selectFieldGroupFromArray';
import Counties from '../../static/counties.json';
import { getSubject } from '../../actions/subjectActions';
import ToastService from '../Toasts/toastService';
import Loader from '../Spinners/spinnerPage';

class EditSubjectPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      middleName: '',
      dob: '',
      ssn: '',
      gender: '',
      city: '',
      state: '',
      county: '',
      errors: {},
      isValid: {},
      counties: [],
      address1: '',
      address2: '',
      email: '',
      phone: '',
      zip: '',
      country: 'US',
      subject_id: '',
      showLoader: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    if (e.target.name === 'state') {
      this.setState({
        counties: Counties[e.target.value.toString()],
        state: e.target.value
      });
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  loadCounties(state) {
    this.setState({
      counties: Counties[state]
    });
  }
  onSubmit(e) {
    this.setState({showLoader: true});
    e.preventDefault();
    try{
      if (this.isValid()) {
        this.props.editSubject({ ...this.state, gender: this.state.gender[0] });
      }
      this.setState({showLoader: false});
      ToastService.show('Successfully edited individual!', 'success');
    } catch (error) {
      // handle error
        console.log('error', error.response);
        this.setState({showLoader: false});
        ToastService.show(`${error.response.statusText},please contact customer support.`, 'error');
    }
  }

  isValid() {
    let { errors, isValid } = validateAddSubject(this.state);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }

  componentDidMount() {
    this.props.getSubject(this.props.location.state.currentSubjectId);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.subject) {
      this.setState({
        subject_id: newProps.subject.subject_id,
        firstName: newProps.subject.name_first,
        lastName: newProps.subject.name_last,
        middleName: newProps.subject.name_middle,
        dob: newProps.subject.dob,
        ssn: newProps.subject.ssn ? newProps.subject.ssn : '',
        gender: JSON.parse(newProps.subject.json_result).gender,
        city: newProps.subject.city,
        state: newProps.subject.state,
        country: newProps.subject.country,
        county: newProps.subject.county,
        address1: newProps.subject.address1,
        address2: newProps.subject.address2,
        email: newProps.subject.email ? newProps.subject.email : '',
        phone: newProps.subject.phone,
        zip: newProps.subject.zip
      });
      this.loadCounties(newProps.subject.state);
    }
  }

  showToast() {
      ToastService.show('Successfully added new card!', 'success');
    }

  render() {
    const loader = this.state.showLoader?<Loader />:null;
    return (
      <Container >
        <Row>
          <Col lg={12}>
            <Card className='card-box mt-5'>
              <Form onSubmit={this.onSubmit}>
                <Card.Body>
                  <Card.Title>
                    <h2>
                      <i className='material-icons md-36'>edit</i>Edit Individual
                    </h2>
                  </Card.Title>
                  <h5 className='mt-5 mb-3'>Individual's Personal Info</h5>
                  <Card.Text />
                  <TextFieldGroup
                    error={this.state.errors.firstName}
                    label='First name'
                    onChange={this.onChange}
                    value={this.state.firstName}
                    field='firstName'
                    required={true}
                  />
                  <TextFieldGroup
                    error={this.state.errors.lastName}
                    label='Last name'
                    onChange={this.onChange}
                    value={this.state.lastName}
                    field='lastName'
                    required={true}
                  />
                  <TextFieldGroup
                    error={this.state.errors.middleName}
                    label='Middle name'
                    onChange={this.onChange}
                    value={this.state.middleName}
                    field='middleName'
                  />
                  <TextFieldGroup
                    error={this.state.errors.dob}
                    label='Date of Birth (Format: YYYY-MM-DD)'
                    onChange={this.onChange}
                    value={this.state.dob}
                    field='dob'
                    required={true}
                  />
                  <TextFieldGroup
                    error={this.state.errors.ssn}
                    label='SSN (Format: AAA-BB-CCCC)'
                    onChange={this.onChange}
                    value={this.state.ssn}
                    field='ssn'
                  />
                  <TextFieldGroup
                    error={this.state.errors.address1}
                    label='Address 1'
                    onChange={this.onChange}
                    value={this.state.address1}
                    field='address1'
                  />
                  <TextFieldGroup
                    error={this.state.errors.address2}
                    label='Address 2'
                    onChange={this.onChange}
                    value={this.state.address2}
                    field='address2'
                  />
                  <SelectFieldGroup
                    error={this.state.errors.gender}
                    label='Gender'
                    onChange={this.onChange}
                    value={this.state.gender}
                    required={true}
                    name='gender'
                  />
                  <TextFieldGroup
                    error={this.state.errors.city}
                    label='City'
                    onChange={this.onChange}
                    value={this.state.city}
                    field='city'
                  />
                  <SelectFieldGroupFromArray
                    error={this.state.errors.state}
                    label='State'
                    onChange={this.onChange}
                    value={this.state.state}
                    field='state'
                    options={States.Values}
                    name='state'
                    placeholder='- - Select state - -'
                    required={true}
                  />
                  <SelectFieldGroupFromArray
                    error={this.state.errors.county}
                    label='County'
                    onChange={this.onChange}
                    value={this.state.county}
                    options={this.state.counties}
                    field='county'
                    name='county'
                    placeholder='- - Select county - -'
                    required={true}
                  />
                  <TextFieldGroup
                    error={this.state.errors.zip}
                    label='Zip'
                    onChange={this.onChange}
                    value={this.state.zip}
                    field='zip'
                  />
                  <TextFieldGroup
                    error={this.state.errors.phone}
                    label='Phone'
                    onChange={this.onChange}
                    value={this.state.phone}
                    field='phone'
                  />
                  <TextFieldGroup
                    error={this.state.errors.email}
                    label='Email'
                    onChange={this.onChange}
                    value={this.state.email}
                    field='email'
                  />
                  {loader}
                  <Button
                    variant='primary'
                    className='mt-3 mb-2'
                    onClick={this.onSubmit}
                  >
                    <i className='material-icons'>loop</i>Update Individual's Info
                  </Button>
                </Card.Body>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapActionsToProps = {
  editSubject,
  getSubject
};

const mapStateToProps = state => {
  return {
    subject: state.subjectReducer.subject
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(EditSubjectPage)
);
