import {
  UPDATE_USER_SUCCESS,
  GET_USER_SUCCESS,
  GET_USER_CARDS_SUCCESS,
  GET_USER_TRANSACTIONS_SUCCESS,
  GET_USERS_SUCCESS,
  UPDATE_USER_BY_ADMIN_SUCCESS
} from '../common/constants';
import userApi from '../api/user-api';
import apiCallsHelper from '../api/apiCallsHelper';
import config from '../common/config';

export function getUserSuccess(user) {
  return {
    type: GET_USER_SUCCESS,
    user: user
  };
}

export function getUsersSuccess(users) {
  return {
    type: GET_USERS_SUCCESS,
    users
  };
}

export function updateUserSuccess(user) {
  return {
    type: UPDATE_USER_SUCCESS,
    user: user
  };
}

export function updateUserByAdminSuccess(user) {
  return {
    type: UPDATE_USER_BY_ADMIN_SUCCESS,
    user: user
  };
}

function getCardsSuccess(cards) {
  return {
    type: GET_USER_CARDS_SUCCESS,
    cards: cards
  };
}

function getUserTransactionsSuccess(transactions) {
  return {
    type: GET_USER_TRANSACTIONS_SUCCESS,
    userTransactions: transactions
  };
}

export function getUser(userId) {
  return function(dispatch) {
    return userApi
      .getUser(userId)
      .then(r => {
        dispatch(getUserSuccess(r));
      })
      .catch(err => {
        throw err;
      });
  };
}

export function getUserWithJwt(userId) {
  return function(dispatch) {
    return userApi
      .getUserWithJwt(userId)
      .then(r => {
        dispatch(getUserSuccess(r));
      })
      .catch(err => {
        throw err;
      });
  };
}

export function updateUser(user) {
  return function(dispatch) {
    return userApi
      .updateUser(user)
      .then(r => {
        dispatch(updateUserSuccess(r));
      })
      .catch(err => {
        throw err;
      });
  };
}

export function getUserCards() {
  return function(dispatch) {
    return apiCallsHelper
      .getWithJWT(`${config.trueFingerPrintsAPI.baseURL}/users/cards/all`)
      .then(r => {
        dispatch(getCardsSuccess(r.data));
      })
      .catch(err => {
        throw err;
      });
  };
}

export function getUserTransactions(req) {
  return function(dispatch) {
    const reqForTransactions = {
      currentPage: req.page,
      searchText: req.searchText
    };
    return apiCallsHelper
      .postWithJWT(
        `${config.trueFingerPrintsAPI.baseURL}/transactions/user-transactions`,
        reqForTransactions
      )
      .then(r => {
        dispatch(getUserTransactionsSuccess(r.data));
      })
      .catch(err => {
        throw err;
      });
  };
}

export function getUsers(req) {
  return function(dispatch) {
    return apiCallsHelper
      .postWithJWT(`${config.trueFingerPrintsAPI.baseURL}/users/all`, req)
      .then(r => {
        dispatch(getUsersSuccess(r.data));
      })
      .catch(err => {
        throw err;
      });
  };
}

export function updateUserByAdmin(user) {
  return function(dispatch) {
    return userApi
      .updateUserByAdmin(user)
      .then(r => {
        dispatch(updateUserByAdminSuccess(r));
      })
      .catch(err => {
        throw err;
      });
  };
}
