import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { getUserTransactions } from '../../actions/userActions';
import { connect } from 'react-redux';
import dateFormat from 'dateformat';

class PaymentHistoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      searchText: ''
    };
    this.loadUserTransactions = this.loadUserTransactions.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  componentDidMount() {
    this.loadUserTransactions();
  }

  loadUserTransactions() {
    this.props.getUserTransactions({ ...this.state });
  }

  handlePageClick = e => {
    this.loadOrders(e.selected + 1);
  };
  render() {
    return (
      <Container>
        <Row>
          <Col lg={12}></Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Card bg='secondary' className='card-box-dark mt-4'>
              <Card.Title className='text-white mb-4'>
                <h2>
                  <i className='material-icons md-36 mt-4 ml-3'>
                    monetization_on
                  </i>
                  Payments
                </h2>
              </Card.Title>

              <Table striped bordered hover variant='dark' responsive='xl'>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Payment description</th>
                    <th>Total Amount ($)</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.userTransactions
                    ? this.props.userTransactions.transactions.map(i => {
                        return (
                          <tr key={i.id}>
                            <td>{dateFormat(i.createdAt, 'mmm dd yyyy')}</td>
                            <td>{i.description}</td>
                            <td>{i.amount / 100}</td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </Table>
              <ReactPaginate
                previousLabel={'< Previous'}
                nextLabel={'Next >'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={
                  this.props.transactions && this.props.transactions.count
                    ? Math.ceil(this.props.transactions.count / 10)
                    : 1
                }
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={'list-group-pagination pagination pb-0'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                disabledClassName={'page-item disabled'}
                activeClassName={'page-item active'}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapActionsToProps = {
  getUserTransactions
};

const mapStateToProps = state => {
  return {
    userTransactions: state.userReducer.userTransactions
  };
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(PaymentHistoryPage);
