import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import UserApi from '../api/user-api';

export default async function validateAddAuthorization(data, email) {
  let errors = {};

  if (Validator.isEmpty(data.firstName)) {
    errors.firstName = 'First name is required';
  }

  if (Validator.isEmpty(data.lastName)) {
    errors.lastName = 'Last name is required';
  }

  if (Validator.isEmpty(data.dob)) {
    errors.dob = 'Date of birth is required';
  }

  if (moment(data.dob).format('YYYY-MM-DD') !== data.dob) {
    errors.dob = 'Date should be in format YYYY-MM-DD';
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = 'Email is required';
  }
  
  if (!email === data.email) {
    if (!Validator.isEmail(data.email)) {
      errors.email = 'Email is invalid';
    }

    if (data.email) {
      const emailAlreadyExist = await UserApi.emailExist(data.email);
      if (emailAlreadyExist.emailExist) {
        errors.email = 'Email already exists';
      }
    }
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
}
