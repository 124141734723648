import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import UserApi from '../api/user-api';

export default function validateUserProfileInput(data, differentEmail) {
  let errors = {};
  if (Validator.isEmpty(data.fullName)) {
    errors.fullName = 'Full name is required';
  }

  if (Validator.isEmpty(data.email)) {
    errors.email = 'Email is required';
  }
  if (!Validator.isEmail(data.email)) {
    errors.email = 'Email is invalid';
  }
  if (differentEmail) {
    const emailAlreadyExist = UserApi.emailExist(data.email);
    if (emailAlreadyExist) {
      errors.email = 'Email already exists';
    }
  }

  if (!Validator.isEmpty(data.password) && data.password.length < 8) {
    errors.password = 'Password must be at least 8 characters';
  } else {
    if (
      !Validator.isEmpty(data.password) &&
      Validator.isEmpty(data.passwordConfirmation)
    ) {
      errors.passwordConfirmation = 'Confirm your password';
    }
    if (!Validator.equals(data.password, data.passwordConfirmation)) {
      errors.passwordConfirmation = 'Passwords must match';
    }
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
}
